import { BASE_API_URL } from "config/CONSTANTS";

export const urlLogin = BASE_API_URL + "/login";
export const urlRegister = BASE_API_URL + "/register";
export const urlSendAuthSms = BASE_API_URL + "/send-auth-sms";
export const urlCheckAuthSms = BASE_API_URL + "/check-auth-sms";
export const urlLoginTwoFactAuth = BASE_API_URL + "/login-two-fact-auth";
export const urlRequestResetPassword = BASE_API_URL + "/request-reset-password";
export const urlConfirmPassword = BASE_API_URL + "/confirm-password";
export const urlLogout = BASE_API_URL + "/logout";
export const urlGuestNewTicket = BASE_API_URL + "/guest-ticket";
export const urlCheckSponsor = BASE_API_URL + "/check-sponsor";
export const urlCheckPasswordStrength = BASE_API_URL + "/check-password-strength";
export const urlCheckCoupon = BASE_API_URL + "/check-coupon";

export const urlUserDashboardDetail = BASE_API_URL + "/user/dashboard";
export const urlUserGetProfileInfo = BASE_API_URL + "/user/get-profile-info";
export const urlUserCheckHasActiveLicense = BASE_API_URL + "/user/check-has-active-license";
export const urlUserCheckHlxPassword = BASE_API_URL + "/user/check-hlx-password";
export const urlUserGetVpsPassword = BASE_API_URL + "/user/vps/get-vps-password";
export const urlUserGetVpsConsoleUrl = BASE_API_URL + "/user/vps/get-console-url";
export const urlUserGetLicensePageDetail = BASE_API_URL + "/user/license/get-page-detail";
export const urlUserUpdateLicenseInfo = BASE_API_URL + "/user/license/update-info";
export const urlUserGetAcademyPageDetail = BASE_API_URL + "/user/academy/get-page-detail";
export const urlUserGetAcademyData = BASE_API_URL + "/user/academy/get-lesson-list";
export const urlUserGetMarketingPageDetail = BASE_API_URL + "/user/marketing/get-page-detail";
export const urlUserGetMarketingRankDetail = BASE_API_URL + "/user/marketing/get-user-rank-detail";
export const urlUserGetWalletPageDetail = BASE_API_URL + "/user/wallet/get-page-detail";
export const urlUserRequestWithdrawal = BASE_API_URL + "/user/wallet/request-withdraw";
export const urlUserDeletePayoutRow = BASE_API_URL + "/user/wallet/delete-user-payout";
export const urlUserDeleteWithdrawalRow = BASE_API_URL + "/user/wallet/delete-user-withdrawal";
export const urlUserGetPaymentListPageDetail = BASE_API_URL + "/user/payment/get-page-detail";
export const urlUserDeletePaymentRow = BASE_API_URL + "/user/payment/delete-user-payment";
export const urlUserGetProfilePageDetail = BASE_API_URL + "/user/profile/get-page-detail";
export const urlUserGetProfile = BASE_API_URL + "/user/profile/get-detail";
export const urlUserUpdateProfile = BASE_API_URL + "/user/profile/update-detail";
export const urlUserUpdateCardInfo = BASE_API_URL + "/user/profile/update-card-detail";
export const urlUserRemoveCardInfo = BASE_API_URL + "/user/profile/remove-card-detail";
export const urlUserCancelMembership = BASE_API_URL + "/user/profile/cancel-membership";
export const urlUserCancelAffiliate = BASE_API_URL + "/user/profile/cancel-affiliate";
export const urlUserGetVerificationPageDetail = BASE_API_URL + "/user/verification/get-page-detail";
export const urlUserSendTwoFactVerificationEmail = BASE_API_URL + "/user/verification/send-verification-email";
export const urlUserConfirmVerificationCode = BASE_API_URL + "/user/verification/confirm";
export const urlUserCompleteVerification = BASE_API_URL + "/user/verification/complete";
export const urlUserCancelVerification = BASE_API_URL + "/user/verification/cancel";
export const urlUserDisableVerification = BASE_API_URL + "/user/verification/disable";
export const urlUserPayLicensePageDetail = BASE_API_URL + "/user/pay/get-page-detail";
export const urlUserPayLicense = BASE_API_URL + "/user/pay/license";
export const urlUserPayAffiliate = BASE_API_URL + "/user/pay/affiliate";
export const urlUserTeamPageDetail = BASE_API_URL + "/user/team/get-page-detail";
export const urlUserGetTankUserList = BASE_API_URL + "/user/team/get-tank-list";
export const urlUserAssignTankUser = BASE_API_URL + "/user/team/assign-tank-user";
export const urlUserGetTicketListPageDetail = BASE_API_URL + "/user/ticket/get-list-page-detail";
export const urlUserDeleteTicket = BASE_API_URL + "/user/ticket/delete-ticket";
export const urlUserSubmitTicket = BASE_API_URL + "/user/ticket/submit-ticket";
export const urlUserGetTicketInfoPageDetail = BASE_API_URL + "/user/ticket/get-info-page-detail";
export const urlUserSubmitTicketMessage = BASE_API_URL + "/user/ticket/submit-ticket-message";
export const urlUserCloseTicket = BASE_API_URL + "/user/ticket/close-ticket";
export const urlUserGetTreeUserList = BASE_API_URL + "/user/marketing/get-data-list";
export const urlUserGetTreeUpLevelUser = BASE_API_URL + "/user/team/get-uplevel-user";
export const urlUserDownloadInvoice = BASE_API_URL + "/user/payment/download-invoice";


export const urlAdminGetProfile = BASE_API_URL + "/admin/get-profile-info";
export const urlAdminUpdateProfile = BASE_API_URL + "/admin/update-profile-info";
export const urlAdminGetUsersPageDetail = BASE_API_URL + "/admin/users/get-page-detail";
export const urlAdminGetUsersList = BASE_API_URL + "/admin/users/get-data-list";
export const urlAdminDeleteUser = BASE_API_URL + "/admin/users/delete";
export const urlAdminSetUserTmpPassword = BASE_API_URL + "/admin/users/set-tmp-password";
export const urlAdminUpdateUserInfo = BASE_API_URL + "/admin/users/update-user-info";
export const urlAdminUpdateUserStatus = BASE_API_URL + "/admin/users/update-user-status";
export const urlAdminGetLicensesList = BASE_API_URL + "/admin/licenses/get-data-list";
export const urlAdminGetPaymentList = BASE_API_URL + "/admin/payments/get-data-list";
export const urlAdminDeletePaymentHistory = BASE_API_URL + "/admin/payments/delete-item";
export const urlAdminGetWithdrawList = BASE_API_URL + "/admin/withdraw/get-data-list";
export const urlAdminChangeWithdrawStatus = BASE_API_URL + "/admin/withdraw/change-status";
export const urlAdminGetTicketList = BASE_API_URL + "/admin/ticket/get-data-list";
export const urlAdminGetTicketInfoPageDetail = BASE_API_URL + "/admin/ticket/get-info-page-detail";
export const urlAdminSubmitTicketMessage = BASE_API_URL + "/admin/ticket/submit-ticket-message";
export const urlAdminGetFeedList = BASE_API_URL + "/admin/feed/get-data-list";
export const urlAdminSubmitFeed = BASE_API_URL + "/admin/feed/submit-feed";
export const urlAdminDeleteFeed = BASE_API_URL + "/admin/feed/delete-feed";
export const urlAdminGetFeedInfoPageDetail = BASE_API_URL + "/admin/feed/get-info-page-detail";
export const urlAdminSubmitAnnouncement = BASE_API_URL + "/admin/announcement/submit";
export const urlAdminStatsPageDetail = BASE_API_URL + "/admin/stats/get-page-detail";
export const urlAdminTeamPageDetail = BASE_API_URL + "/admin/team/get-page-detail";
export const urlAdminDownloadUserList = BASE_API_URL + "/admin/users/download";
export const urlAdminRankPageDetail = BASE_API_URL + "/admin/rank/get-page-detail";
export const urlAdminGetTreeUpLevelUser = BASE_API_URL + "/admin/team/get-uplevel-user";

export const urlAdminGetCouponList = BASE_API_URL + "/admin/coupon/get-data-list";
export const urlAdminSubmitCoupon = BASE_API_URL + "/admin/coupon/submit-coupon";
export const urlAdminDeleteCoupon = BASE_API_URL + "/admin/coupon/delete-coupon";
export const urlAdminGetCouponInfo = BASE_API_URL + "/admin/coupon/get-info";
