import HomeLayout from "layouts/HomeLayout/HomeLayout";
import HomeLogoBar from "layouts/HomeLayout/Includes/HomeLogoBar";
import {
  ROUTE_COMPANY_REGISTER,
  ROUTE_REGISTER,
  ROUTE_ROOT,
} from "navigation/CONSTANTS";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import { Link, withRouter } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  empty,
  get_data_value,
  isEmpty,
  is_empty,
  showToast,
  show_loading,
  to_array,
  trim_phone,
  ValidateEmail,
  jQuery,
} from "utils/GlobalFunctions";
import {
  apiCheckAuthSms,
  apiCheckCoupon,
  apiCheckPasswordStrength,
  apiCheckSponsor,
  apiLogin,
  apiLogout,
  apiRegister,
  apiSendAuthSms,
} from "services/loginService";
import { updateUser } from "redux/actions/userActions";
import { countryList } from "utils/countryList";
import CreditCardForm from "components/CreditCardForm/CreditCardForm";
import { REGISTER_FUNC, SMS_FUNC } from "config/CONSTANTS";
import AuthSmsCodeModal from "components/AuthSmsCodeModal/AuthSmsCodeModal";
import { console_log } from "utils/logger";

const CompanyRegisterPage2 = (props) => {
  const { pageData, setPageData, goRegStep } = props;
  const curStep = 2;

  const dispatch = useDispatch();
  const [showConfirmModal, setShowconfirmModal] = useState(false);

  useEffect(() => {}, [props]);
  const showTwoStepCodeModal = () => {
    setShowconfirmModal(true);
  };

  //////////////////////////////////////////////////////////////////

  const userDataStore = useSelector((x) => x.userDataStore);
  let curYear = new Date().getFullYear();
  const initUserData = {
    user_name: "",
    user_first_name: "",
    user_last_name: "",
    user_phone: "",
    user_email: "",
    user_confirm_email: "",
    user_password: "",
    user_password1: "",
    user_type: "0",
    billing_street: "",
    billing_city: "",
    billing_state: "",
    billing_zip_code: "",
    billing_country: "US",
    owner: "",
    card_number: "",
    cvv: "",
    exp_month: "01",
    exp_year: curYear - 2000,
    card_type: "",
    card_is_valid: false,
    agree_terms_condition: false,
    watched_compliance: false,
    trial_policy: false,
  };
  const [userData, setUserData] = useState(initUserData);
  const [errorField, setErrorField] = useState([]);
  //const [isBusy, setIsBusy] = useState(false);

  const validateFields = () => {
    var errorList = [];
    errorList = isEmpty(userData, "user_name", errorList);
    errorList = isEmpty(userData, "user_first_name", errorList);
    errorList = isEmpty(userData, "user_last_name", errorList);
    errorList = isEmpty(userData, "user_phone", errorList);
    errorList = isEmpty(userData, "user_email", errorList);
    errorList = isEmpty(userData, "user_confirm_email", errorList);
    errorList = isEmpty(userData, "user_password", errorList);
    errorList = isEmpty(userData, "user_password1", errorList);
    errorList = isEmpty(userData, "user_type", errorList);
    errorList = isEmpty(userData, "billing_street", errorList);
    errorList = isEmpty(userData, "billing_city", errorList);
    errorList = isEmpty(userData, "billing_state", errorList);
    errorList = isEmpty(userData, "billing_zip_code", errorList);
    errorList = isEmpty(userData, "billing_country", errorList);
    errorList = isEmpty(userData, "owner", errorList);
    errorList = isEmpty(userData, "card_number", errorList);
    errorList = isEmpty(userData, "cvv", errorList);
    errorList = isEmpty(userData, "exp_month", errorList);
    errorList = isEmpty(userData, "exp_year", errorList);
    errorList = isEmpty(userData, "card_is_valid", errorList);
    errorList = isEmpty(userData, "agree_terms_condition", errorList);
    errorList = isEmpty(userData, "watched_compliance", errorList);
    errorList = isEmpty(userData, "trial_policy", errorList);

    setErrorField([...errorList]);
    return errorList.length > 0 || errorField.length > 0 ? false : true;
  };

  const onChangeFormField = (e) => {
    let field_name = e.target.name;
    let field_value = e.target.value;
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    userData[field_name] = field_value;
    setUserData({ ...userData });
    if (field_name === "user_password") {
      checkPasswordStrength(field_value);
    }
  };
  const onChangeFormCheckboxField = (e) => {
    let field_name = e.target.name;
    let checked = e.target.checked;
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    userData[field_name] = checked;
    setUserData({ ...userData });
  };
  const onChangePhoneField = (value, country, e, formattedValue) => {
    let phone_number = trim_phone(formattedValue);
    if (errorField.includes("user_phone")) {
      let errors = errorField.filter((x) => x !== "user_phone");
      setErrorField([...errors]);
    }
    userData["user_phone"] = phone_number;
    setUserData({ ...userData });
  };
  const onChangeUserType = (e, user_type) => {
    userData["user_type"] = user_type;
    setUserData({ ...userData });
  };
  const submitModalData = (codeData) => {
    let codeStr = to_array(codeData).join("");
    show_loading(true);
    apiCheckAuthSms(userData, codeStr)
      .then((data) => {
        show_loading(false);
        if (data.status === "1") {
          doRegister();
        } else {
          showToast(data.message, "error");
        }
      })
      .catch((err) => {
        show_loading(false);
        showToast(err, "error");
      });
  };
  const onClickRegister = () => {
    let is_valid = validateFields();
    if (is_valid) {
      if (userData["user_email"] !== userData["user_confirm_email"]) {
        showToast("Invalid or not matching email address", "error");
        return false;
      }
      if (!ValidateEmail(userData["user_confirm_email"])) {
        showToast("Invalid or not matching email address", "error");
        return false;
      }
      if (userData["user_password"] !== userData["user_password1"]) {
        showToast("Password must match!", "error");
        return false;
      }
      if (!userData["agree_terms_condition"]) {
        showToast("Please agree to the terms and conditions", "error");
        return false;
      }
      if (!userData["watched_compliance"]) {
        showToast("Please watch the compliance first", "error");
        return false;
      }
      if (!userData["trial_policy"]) {
        showToast("Please agree to the 7-Day trial policy", "error");
        return false;
      }
      if (SMS_FUNC === "disabled") {
        //if test mode then register directly
        doRegister();
      } else {
        //if live mode then first verify phone number
        show_loading(true);
        apiSendAuthSms(userData)
          .then((data) => {
            show_loading(false);
            if (data.status === "1") {
              showToast(data.message, "success");
              showTwoStepCodeModal();
            } else {
              showToast(data.message, "error");
            }
          })
          .catch((err) => {
            show_loading(false);
            showToast(err, "error");
          });
      }
    } else {
      window.scrollTo(0, 0); //
    }
  };
  const doRegister = () => {
    show_loading(true);
  };

  const doLogout = () => {
    if (empty(userDataStore["token"])) {
      return false;
    }

    apiLogout(userDataStore["token"])
      .then((api_res) => {})
      .catch((err) => {});
    dispatch(updateUser({ token: "" }));
  };
  const checkPasswordStrength = (password) => {
    if (password === "") {
      return false;
    }
    apiCheckPasswordStrength(password)
      .then((data) => {
        if (data.status === "1") {
          setPasswdStrengthGood(true);
        } else {
          setPasswdStrengthGood(false);
          setPasswdStrengthErrMessage(data.message);
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  const [passwdStrengthGood, setPasswdStrengthGood] = useState(true);
  const [passwdStrengthErrMessage, setPasswdStrengthErrMessage] = useState("");

  const [couponName, setCouponName] = useState("");
  const [couponApplied, setCouponApplied] = useState(false);
  const [couponAppliedTxt, setCouponAppliedTxt] = useState("");
  const [couponChecking, setCouponChecking] = useState(false);
  const onChangeCouponName = (e) => {
    let field_value = e.target.value;
    setCouponName(field_value);
  };
  const onClickCheckCoupon = () => {
    if (couponChecking) {
      return false;
    }
    if (couponName === "") {
      setTimeout(function () {
        jQuery("#coupon-name").focus();
      }, 100);
      return false;
    }
    if (userData["user_type"] == "1") {
      showToast("You must choose gold package", "error");
      setCouponApplied(false);
      return false;
    }
    setCouponChecking(true);
    setTimeout(function () {
      doCheckCoupon();
    }, 500);
  };
  const doCheckCoupon = () => {
    apiCheckCoupon({ coupon: couponName })
      .then((data) => {
        setCouponChecking(false);
        if (data.status === "1") {
          setCouponApplied(true);
          setCouponAppliedTxt(data.message);
          showToast(data.message, "success");
        } else {
          setCouponApplied(false);
          showToast(data.message, "error");
        }
      })
      .catch((err) => {
        setCouponChecking(false);
        showToast(err, "error");
      });
  };

  //////////////////////////////////////////////////////////////////////////////////
  const [registrationDate, setRegistrationDate] = useState(new Date());
  const setUserRegistrationDate = (d) => {
    console.log(d);
    setRegistrationDate(d);
  };

  const totalEstimatedNetWorthOptions = [
    {
      value: 0,
      text: "$0 - $10,000",
    },
    {
      value: 1,
      text: "$10,001 - $50,000",
    },
    {
      value: 2,
      text: "$50,001 - $100,000",
    },
    {
      value: 3,
      text: "$100,001+",
    },
  ];

  const totalEstimatedAnnualOptions = [
    {
      value: 0,
      text: "$0 - $10,000",
    },
    {
      value: 1,
      text: "$10,001 - $50,000",
    },
    {
      value: 2,
      text: "$50,001 - $100,000",
    },
    {
      value: 3,
      text: "$100,001 - $500,000",
    },
    {
      value: 4,
      text: "$500,000+",
    },
  ];

  const sourceIncomeOptions = [
    {
      value: 0,
      text: "Income from profession/emplyment",
    },
    {
      value: 1,
      text: "Company Ownership/Sale of Company Assets",
    },
    {
      value: 2,
      text: "Investments or Savings",
    },
    {
      value: 3,
      text: "Other",
    },
  ];

  const yesNoOptions = [
    {
      value: 1,
      text: "Yes",
    },
    {
      value: 0,
      text: "No",
    },
  ];

  const companyDocumentOptions = [
    {
      value: 0,
      text: "Articles of Organizations",
    },
    {
      value: 1,
      text: "Articles of Corporation",
    },
    {
      value: 2,
      text: "Trust",
    },
  ];

  const nextRegStep = () => {
    goRegStep(curStep + 1);
  };

  const prevRegStep = () => {
    if (curStep > 0) {
      goRegStep(curStep - 1);
    }
  };

  return (
    <div className="reg-form">
      <div className="row">
        <div className="col-md-12">
          <div className="block">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Total Estimated Net Worth($)?</label>
                  <select
                    id="total_estimated_net_worth"
                    name="total_estimated_net_worth"
                    className={
                      "form-control appearence" +
                      (errorField.includes("total_estimated_net_worth")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(
                      userData,
                      "total_estimated_net_worth",
                      ""
                    )}
                  >
                    {totalEstimatedNetWorthOptions.map((optionItem, index) => (
                      <option key={index} value={optionItem.value}>
                        {optionItem.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Total Estimated Annual Income($)?</label>
                  <select
                    id="total_estimated_annual_income"
                    name="total_estimated_annual_income"
                    className={
                      "form-control appearence" +
                      (errorField.includes("total_estimated_annual_income")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(
                      userData,
                      "total_estimated_annual_income",
                      ""
                    )}
                  >
                    {totalEstimatedAnnualOptions.map((optionItem, index) => (
                      <option key={index} value={optionItem.value}>
                        {optionItem.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Source of Income:</label>
                  <select
                    id="source_income"
                    name="source_income"
                    className={
                      "form-control appearence" +
                      (errorField.includes("source_income")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(userData, "source_income", "")}
                  >
                    {sourceIncomeOptions.map((optionItem, index) => (
                      <option key={index} value={optionItem.value}>
                        {optionItem.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Employment Status:</label>
                  <select
                    id="employment_status"
                    name="employment_status"
                    className={
                      "form-control appearence" +
                      (errorField.includes("employment_status")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(userData, "employment_status", "")}
                  >
                    {yesNoOptions.map((optionItem, index) => (
                      <option key={index} value={optionItem.value}>
                        {optionItem.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-12">
                <h6 className="main-font-color">Trading Experience:</h6>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Forex and Other Market Instruments:</label>
                  <select
                    id="forex_other_instruments"
                    name="forex_other_instruments"
                    className={
                      "form-control appearence" +
                      (errorField.includes("forex_other_instruments")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(
                      userData,
                      "forex_other_instruments",
                      ""
                    )}
                  >
                    {yesNoOptions.map((optionItem, index) => (
                      <option key={index} value={optionItem.value}>
                        {optionItem.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>
                    Derivative Products are Suitable as Part of my Investment
                    Objectives and Attitude Towards Risk and I'am Able to Access
                    the Risk Involved in Trading Them, Including the Possibility
                    that I may Lose All of my Capital:
                  </label>
                  <select
                    id="product_sutable"
                    name="product_sutable"
                    className={
                      "form-control appearence" +
                      (errorField.includes("product_sutable")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(userData, "product_sutable", "")}
                  >
                    {yesNoOptions.map((optionItem, index) => (
                      <option key={index} value={optionItem.value}>
                        {optionItem.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>
                    I Have Previous Professional Qualifications and/or Work
                    Experience in the Financial Services Industry:
                  </label>
                  <select
                    id="has_previous_work"
                    name="has_previous_work"
                    className={
                      "form-control appearence" +
                      (errorField.includes("has_previous_work")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(userData, "has_previous_work", "")}
                  >
                    {yesNoOptions.map((optionItem, index) => (
                      <option key={index} value={optionItem.value}>
                        {optionItem.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-10 offset-md-1">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Company Document</label>
                      <select
                        id="company_document"
                        name="company_document"
                        className={
                          "form-control appearence" +
                          (errorField.includes("company_document")
                            ? " is-invalid"
                            : "")
                        }
                        onChange={(e) => {
                          onChangeFormField(e);
                        }}
                        value={get_data_value(userData, "company_document", "")}
                      >
                        {/* <option value="">Select</option> */}
                        {companyDocumentOptions.map((optionItem, index) => (
                          <option key={index} value={optionItem.value}>
                            {optionItem.text}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="file-upload-group">
                        <input
                          type="text"
                          className={`form-control file-upload-info hidden`}
                          readOnly=""
                          placeholder="No file selected"
                        />
                        <button
                          className="file-upload-browse btn btn-file-upload"
                          type="button"
                        >
                          Upload
                        </button>
                        <input
                          type="file"
                          name="upload_file"
                          className="file-upload-default upload_file"
                          data-key="upload_file"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="reg-btn-group text-center">
            <button
              className="btn btn-danger btn-reg-next"
              type="button"
              onClick={(e) => {
                nextRegStep();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(CompanyRegisterPage2);
