var Backend = {
    
    askSupport: function askSupport(name,email,subject,message,callback){
        fetch('https://wlj2acontacto.gntapi.com/api/whiteLevel/info/contacto',{
            "method": "POST",
            "headers": {
                'Content-Type': 'application/json',
            },
            "body": JSON.stringify({
                "nombre": name,
                "correo": email,
                "asunto":subject,
                "mensaje" : message,
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
        callback(responseJson)
        })
        .catch((error) => {
    
        })
    },
};
export default Backend;