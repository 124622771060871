import CompanyUserBlock from "pages/Home/CompanyRegisterPage/inc/CompanyUserBlock";

import {
  ROUTE_COMPANY_REGISTER,
  ROUTE_REGISTER,
  ROUTE_ROOT,
} from "navigation/CONSTANTS";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import { Link, withRouter } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  empty,
  get_data_value,
  isEmpty,
  is_empty,
  showToast,
  show_loading,
  to_array,
  trim_phone,
  ValidateEmail,
  jQuery,
  intval,
  genNumArr,
  is_null,
} from "utils/GlobalFunctions";
import {
  apiCheckAuthSms,
  apiCheckCoupon,
  apiCheckPasswordStrength,
  apiCheckSponsor,
  apiLogin,
  apiLogout,
  apiRegister,
  apiSendAuthSms,
} from "services/loginService";
import { updateUser } from "redux/actions/userActions";
import { countryList } from "utils/countryList";
import CreditCardForm from "components/CreditCardForm/CreditCardForm";
import { REGISTER_FUNC, SMS_FUNC } from "config/CONSTANTS";
import AuthSmsCodeModal from "components/AuthSmsCodeModal/AuthSmsCodeModal";
import { console_log } from "utils/logger";

const CompanyShareHolderList = (props) => {
  const { pageData, setPageData } = props;
  const [boxNumList, setBoxNumList] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    let optionList = [];
    if (!is_null(pageData[1]) && pageData[1]["share_holder_count"] > 0) {
      optionList = genNumArr(pageData[1]["share_holder_count"]);
    }
    setBoxNumList(optionList);
    console_log("pageData", pageData);
    console_log("optionList", optionList);
  }, [props]);
  //////////////////////////////////////////////////////////////////
  const userDataStore = useSelector((x) => x.userDataStore);
  let curYear = new Date().getFullYear();
  const initUserData = {
    user_name: "",
    user_first_name: "",
    user_last_name: "",
    user_phone: "",
    user_email: "",
    user_confirm_email: "",
    user_password: "",
    user_password1: "",
    user_type: "0",
    billing_street: "",
    billing_city: "",
    billing_state: "",
    billing_zip_code: "",
    billing_country: "US",
    owner: "",
    card_number: "",
    cvv: "",
    exp_month: "01",
    exp_year: curYear - 2000,
    card_type: "",
    card_is_valid: false,
    agree_terms_condition: false,
    watched_compliance: false,
    trial_policy: false,
  };
  const [userData, setUserData] = useState(initUserData);
  const [errorField, setErrorField] = useState([]);
  //const [isBusy, setIsBusy] = useState(false);
  const validateFields = () => {
    var errorList = [];
    errorList = isEmpty(userData, "user_name", errorList);
    errorList = isEmpty(userData, "user_first_name", errorList);
    errorList = isEmpty(userData, "user_last_name", errorList);

    setErrorField([...errorList]);
    return errorList.length > 0 || errorField.length > 0 ? false : true;
  };

  const onChangeFormField = (e) => {
    let field_name = e.target.name;
    let field_value = e.target.value;
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    userData[field_name] = field_value;
    setUserData({ ...userData });
    console_log("userData", userData);
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="company-share-holder-list">
      {boxNumList.map((val, index) => {
        return (
          <CompanyUserBlock
            key={index}
            pageData={pageData}
            setPageData={setPageData}
            boxNum={val}
            boxClass="company-share-holder-box"
            boxName="ShareHolder"
          />
        );
      })}
    </div>
  );
};

export default withRouter(CompanyShareHolderList);
