import HomeLayout from "layouts/HomeLayout/HomeLayout";
import HomeLogoBar from "layouts/HomeLayout/Includes/HomeLogoBar";
import {
  ROUTE_COMPANY_REGISTER,
  ROUTE_REGISTER,
  ROUTE_ROOT,
} from "navigation/CONSTANTS";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import { Link, withRouter } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  empty,
  get_data_value,
  isEmpty,
  is_empty,
  showToast,
  show_loading,
  to_array,
  trim_phone,
  ValidateEmail,
  jQuery,
} from "utils/GlobalFunctions";
import {
  apiCheckAuthSms,
  apiCheckCoupon,
  apiCheckPasswordStrength,
  apiCheckSponsor,
  apiLogin,
  apiLogout,
  apiRegister,
  apiSendAuthSms,
} from "services/loginService";
import { updateUser } from "redux/actions/userActions";
import { countryList } from "utils/countryList";
import CreditCardForm from "components/CreditCardForm/CreditCardForm";
import { REGISTER_FUNC, SMS_FUNC } from "config/CONSTANTS";
import AuthSmsCodeModal from "components/AuthSmsCodeModal/AuthSmsCodeModal";
import { console_log } from "utils/logger";

const CompanyRegisterPage0 = (props) => {
  const { pageData, setPageData, goRegStep } = props;
  const curStep = 0

  const dispatch = useDispatch();
  const [showConfirmModal, setShowconfirmModal] = useState(false);

  useEffect(() => {
 
  }, [props]);
  const showTwoStepCodeModal = () => {
    setShowconfirmModal(true);
  };

  //////////////////////////////////////////////////////////////////

  const userDataStore = useSelector((x) => x.userDataStore);
  let curYear = new Date().getFullYear();
  const initUserData = {
    user_name: "",
    user_first_name: "",
    user_last_name: "",
    user_phone: "",
    user_email: "",
    user_confirm_email: "",
    user_password: "",
    user_password1: "",
    user_type: "0",
    billing_street: "",
    billing_city: "",
    billing_state: "",
    billing_zip_code: "",
    billing_country: "US",
    owner: "",
    card_number: "",
    cvv: "",
    exp_month: "01",
    exp_year: curYear - 2000,
    card_type: "",
    card_is_valid: false,
    agree_terms_condition: false,
    watched_compliance: false,
    trial_policy: false,
  };
  const [userData, setUserData] = useState(initUserData);
  const [errorField, setErrorField] = useState([]);
  //const [isBusy, setIsBusy] = useState(false);

  const validateFields = () => {
    var errorList = [];
    errorList = isEmpty(userData, "user_name", errorList);
    errorList = isEmpty(userData, "user_first_name", errorList);
    errorList = isEmpty(userData, "user_last_name", errorList);
    errorList = isEmpty(userData, "user_phone", errorList);
    errorList = isEmpty(userData, "user_email", errorList);
    errorList = isEmpty(userData, "user_confirm_email", errorList);
    errorList = isEmpty(userData, "user_password", errorList);
    errorList = isEmpty(userData, "user_password1", errorList);
    errorList = isEmpty(userData, "user_type", errorList);
    errorList = isEmpty(userData, "billing_street", errorList);
    errorList = isEmpty(userData, "billing_city", errorList);
    errorList = isEmpty(userData, "billing_state", errorList);
    errorList = isEmpty(userData, "billing_zip_code", errorList);
    errorList = isEmpty(userData, "billing_country", errorList);
    errorList = isEmpty(userData, "owner", errorList);
    errorList = isEmpty(userData, "card_number", errorList);
    errorList = isEmpty(userData, "cvv", errorList);
    errorList = isEmpty(userData, "exp_month", errorList);
    errorList = isEmpty(userData, "exp_year", errorList);
    errorList = isEmpty(userData, "card_is_valid", errorList);
    errorList = isEmpty(userData, "agree_terms_condition", errorList);
    errorList = isEmpty(userData, "watched_compliance", errorList);
    errorList = isEmpty(userData, "trial_policy", errorList);

    setErrorField([...errorList]);
    return errorList.length > 0 || errorField.length > 0 ? false : true;
  };

  const onChangeFormField = (e) => {
    let field_name = e.target.name;
    let field_value = e.target.value;
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    userData[field_name] = field_value;
    setUserData({ ...userData });
    if (field_name === "user_password") {
      checkPasswordStrength(field_value);
    }
  };
  const onChangeFormCheckboxField = (e) => {
    let field_name = e.target.name;
    let checked = e.target.checked;
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    userData[field_name] = checked;
    setUserData({ ...userData });
  };
  const onChangePhoneField = (value, country, e, formattedValue) => {
    let phone_number = trim_phone(formattedValue);
    if (errorField.includes("user_phone")) {
      let errors = errorField.filter((x) => x !== "user_phone");
      setErrorField([...errors]);
    }
    userData["user_phone"] = phone_number;
    setUserData({ ...userData });
  };
  const onChangeUserType = (e, user_type) => {
    userData["user_type"] = user_type;
    setUserData({ ...userData });
  };
  const submitModalData = (codeData) => {
    let codeStr = to_array(codeData).join("");
    show_loading(true);
    apiCheckAuthSms(userData, codeStr)
      .then((data) => {
        show_loading(false);
        if (data.status === "1") {
          doRegister();
        } else {
          showToast(data.message, "error");
        }
      })
      .catch((err) => {
        show_loading(false);
        showToast(err, "error");
      });
  };
  const onClickRegister = () => {
    let is_valid = validateFields();
    if (is_valid) {
      
       
      if (userData["user_email"] !== userData["user_confirm_email"]) {
        showToast("Invalid or not matching email address", "error");
        return false;
      }
      if (!ValidateEmail(userData["user_confirm_email"])) {
        showToast("Invalid or not matching email address", "error");
        return false;
      }
      if (userData["user_password"] !== userData["user_password1"]) {
        showToast("Password must match!", "error");
        return false;
      }
      if (!userData["agree_terms_condition"]) {
        showToast("Please agree to the terms and conditions", "error");
        return false;
      }
      if (!userData["watched_compliance"]) {
        showToast("Please watch the compliance first", "error");
        return false;
      }
      if (!userData["trial_policy"]) {
        showToast("Please agree to the 7-Day trial policy", "error");
        return false;
      }
      if (SMS_FUNC === "disabled") {
        //if test mode then register directly
        doRegister();
      } else {
        //if live mode then first verify phone number
        show_loading(true);
        apiSendAuthSms(userData)
          .then((data) => {
            show_loading(false);
            if (data.status === "1") {
              showToast(data.message, "success");
              showTwoStepCodeModal();
            } else {
              showToast(data.message, "error");
            }
          })
          .catch((err) => {
            show_loading(false);
            showToast(err, "error");
          });
      }
    } else {
      window.scrollTo(0, 0); //
    }
  };
  const doRegister = () => {
    show_loading(true);
  };

  const doLogout = () => {
    if (empty(userDataStore["token"])) {
      return false;
    }

    apiLogout(userDataStore["token"])
      .then((api_res) => {})
      .catch((err) => {});
    dispatch(updateUser({ token: "" }));
  };
  const checkPasswordStrength = (password) => {
    if (password === "") {
      return false;
    }
    apiCheckPasswordStrength(password)
      .then((data) => {
         
        if (data.status === "1") {
          setPasswdStrengthGood(true);
        } else {
          setPasswdStrengthGood(false);
          setPasswdStrengthErrMessage(data.message);
        }
      })
      .catch((err) => {       
        
        showToast(err, "error");
      });
  };
 
  const [passwdStrengthGood, setPasswdStrengthGood] = useState(true);
  const [passwdStrengthErrMessage, setPasswdStrengthErrMessage] = useState("");

  const [couponName, setCouponName] = useState("");
  const [couponApplied, setCouponApplied] = useState(false);
  const [couponAppliedTxt, setCouponAppliedTxt] = useState("");
  const [couponChecking, setCouponChecking] = useState(false);
  const onChangeCouponName = (e) => {
    let field_value = e.target.value;
    setCouponName(field_value);
  };
  const onClickCheckCoupon = () => {
    if (couponChecking) {
      return false;
    }
    if (couponName === "") {
      setTimeout(function () {
        jQuery("#coupon-name").focus();
      }, 100);
      return false;
    }
    if (userData["user_type"] == "1") {
      showToast("You must choose gold package", "error");
      setCouponApplied(false);
      return false;
    }
    setCouponChecking(true);
    setTimeout(function () {
      doCheckCoupon();
    }, 500);
  };
  const doCheckCoupon = () => {
    apiCheckCoupon({ coupon: couponName })
      .then((data) => {
        setCouponChecking(false);
        if (data.status === "1") {
          setCouponApplied(true);
          setCouponAppliedTxt(data.message);
          showToast(data.message, "success");
        } else {
          setCouponApplied(false);
          showToast(data.message, "error");
        }
      })
      .catch((err) => {
        setCouponChecking(false);
        showToast(err, "error");
      });
  };

  const [registrationDate, setRegistrationDate] = useState(new Date());
  const setUserRegistrationDate = (d) => {
    console.log(d);
    setRegistrationDate(d)
  };
  const nextRegStep = () => {
      goRegStep(curStep + 1)
  };

  const prevRegStep = () => {
    if(curStep > 0) {
      goRegStep(curStep - 1)
    }
  };

  return (
    <div className="reg-form">
      <div className="row">
        <div className="col-md-12">
          <div className="user-info-block">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label>Company Name:</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (errorField.includes("company_name") ? " is-invalid" : "")
                    }
                    id="company_name"
                    name="company_name"
                    placeholder="Company Name"
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Company Website:</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (errorField.includes("company_website")
                        ? " is-invalid"
                        : "")
                    }
                    id="company_website"
                    name="company_website"
                    placeholder="Company Website"
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Skype ID / Whatapp / Telegram:</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (errorField.includes("contact_id") ? " is-invalid" : "")
                    }
                    id="contact_id"
                    name="contact_id"
                    placeholder="Skype ID / Whatapp / Telegram"
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Country:</label>
                  <select
                    id="country"
                    name="country"
                    className={
                      "form-control" +
                      (errorField.includes("country") ? " is-invalid" : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(userData, "country", "US")}
                  >
                    {countryList.map((countryItem, index) => (
                      <option key={index} value={countryItem.code}>
                        {countryItem.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Mobile Number:</label>
                  <div className="phone-number-box">
                    <PhoneInput
                      country={"us"}
                      value={get_data_value(userData, "user_phone")}
                      placeholder=""
                      onChange={(value, country, e, formattedValue) => {
                        onChangePhoneField(value, country, e, formattedValue);
                      }}
                      inputProps={{
                        type: "tel",
                        className: "form-control phone_number",
                        id: "phone",
                        name: "user_phone",
                        placeholder: "",
                      }}
                    />

                    <input type="hidden" name="phone_number" />
                    <input type="hidden" name="dial_code" />
                    <button
                      className="a-btn btn-remove-tel"
                      onClick={(e) => {
                        onChangePhoneField("", "us", e, "");
                      }}
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Registration Date:</label>
                  <DatePicker
                    className={
                      "form-control" +
                      (errorField.includes("registration_date")
                        ? " is-invalid"
                        : "")
                    }
                    id="registration_date"
                    name="registration_date"
                    placeholder="MM/DD/YYYY"
                    dateFormat="MM/dd/yyyy"
                    maxDate={new Date()}
                    minDate={new Date(1950, 1, 1)}
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown={true}
                    showYearDropdown
                    selected={registrationDate}
                    onChange={(d) => {
                      setUserRegistrationDate(d);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="user-address-block" id="user-address-block">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Address:</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (errorField.includes("address") ? " is-invalid" : "")
                    }
                    id="address"
                    name="address"
                    placeholder="Address"
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>State:</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (errorField.includes("state") ? " is-invalid" : "")
                    }
                    id="state"
                    name="state"
                    placeholder="State"
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>City:</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (errorField.includes("city") ? " is-invalid" : "")
                    }
                    id="city"
                    name="city"
                    placeholder="City"
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Zip Code:</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (errorField.includes("zip_code") ? " is-invalid" : "")
                    }
                    id="zip_code"
                    name="zip_code"
                    placeholder="Zip Code"
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Year Moved to Address:</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (errorField.includes("address1") ? " is-invalid" : "")
                    }
                    id="address1"
                    name="address1"
                    placeholder="Address"
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="user-credential-block" id="user-credential-block">
            <h6 className="box-title text-normal-spacing mt-3 mb-20 main-font-color">
              Login Information:
            </h6>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label>Email:</label>
                  <input
                    type="email"
                    className={
                      "form-control" +
                      (errorField.includes("email") ? " is-invalid" : "")
                    }
                    id="email"
                    name="email"
                    placeholder="Email"
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Password:</label>
                  <input
                    type="password"
                    className={
                      "form-control" +
                      (errorField.includes("password") ? " is-invalid" : "")
                    }
                    id="password"
                    name="password"
                    placeholder="Password"
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Confirm Password:</label>
                  <input
                    type="password"
                    className={
                      "form-control" +
                      (errorField.includes("password_c") ? " is-invalid" : "")
                    }
                    id="password_c"
                    name="password_c"
                    placeholder="Confirm Password"
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="reg-btn-group text-center">
            <button
              className="btn btn-danger btn-reg-next"
              type="button"
              onClick={(e) => {
                nextRegStep();
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(CompanyRegisterPage0);
