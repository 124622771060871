import React, {  } from "react";
import { Switch, Route } from "react-router-dom";
import {
  ROUTE_LOGIN,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_REGISTER,
  ROUTE_COMPANY_REGISTER,
  ROUTE_INDIVIDUAL_REGISTER,
} from "navigation/CONSTANTS";
import HomePage from "pages/Home/HomePage/HomePage";
import LoginPage from "pages/Home/LoginPage/LoginPage";
import RegisterPage from "pages/Home/RegisterPage/RegisterPage";
import CompanyRegisterPage from "pages/Home/CompanyRegisterPage/CompanyRegisterPage";
import IndividualRegisterPage from "pages/Home/IndividualRegisterPage/IndividualRegisterPage";
 
export const RouterConfig = () => {
  return (
    <div>
      <Switch>
        <Route exact path={"/"} component={HomePage} />
        <Route exact path={ROUTE_LOGIN} component={LoginPage} />
        <Route exact path={ROUTE_REGISTER} component={RegisterPage} />
        <Route exact path={ROUTE_COMPANY_REGISTER} component={CompanyRegisterPage} />
        <Route exact path={ROUTE_COMPANY_REGISTER + "/:step"} component={CompanyRegisterPage} />
        <Route exact path={ROUTE_INDIVIDUAL_REGISTER} component={IndividualRegisterPage} />
        <Route exact path={ROUTE_INDIVIDUAL_REGISTER + "/:step"} component={IndividualRegisterPage} />

        <Route exact path={ROUTE_FORGOT_PASSWORD} component={LoginPage} />

        {/* <Route exact path="" component={NotFoundPage} /> */}
      </Switch>
    </div>
  );
};




