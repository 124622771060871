import {
  ROUTE_LOGIN,
} from "navigation/CONSTANTS";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import TermsConditionsModal from "./TermsConditionsModal";
import PrivacyPolicyModal from "./PrivacyPolicyModal";
import { URL_FORGOT_PASSWORD, URL_LOGIN } from "config/CONSTANTS";

const HomeFooter = () => {

  const [visibleTermsConditionsModal, setVisibleTermsConditionsModal] = useState(false);
  const [visiblePrivacyPolicyModal, setVisiblePrivacyPolicyModal] = useState(false);

  const showTermsConditions = () => {
    setVisibleTermsConditionsModal(true);
  };
  const showPrivacyPolicy = () => {
    setVisiblePrivacyPolicyModal(true);
  };

  return (
    <div className="landing-page-footer landing_black" id="contact">
      <div className="landing_container footer-section">
        <div className="footer-body">
          <div className="row">
            <div className="col-md-3">
              <div className="contact-address-block">
                <p className="footer-text">Address:</p>
                <p className="footer-text">
                  MYBROKER FX SAPI DE CV <br />
                  Paseo de La Reforma 2233 - PH1,  <br />
                  Col. Lomas de Chapultepec,  <br />
                  Mexico City, Mexico 11000<br />
                  Within Mexico: +52 800-880-0444<br />
                  International: +1 (844) 620-2866<br />
                  Fax: +1 (844) 620-2866<br />
                </p>
                <p className="footer-text">Mailing Address:</p>
                <p className="footer-text">
                  Av. Club de Golf Lomas 2  Residencial Acueducto Apt 504,<br />
                  City Huixquilucan,   <br />
                  Estado de Mexico, Zip: 52787<br />
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <p className="footer-text Text_container__fiiSE Text_body20__e5y_H mt-4">
                Risk Warning: HIGH RISK INVESTMENT WARNING:  Risk Warning: CFDs are margined products; it is possible to lose more than your initial margin deposit or credit allocation as well as any variation margin that you may be required to deposit from time to time. Therefore, you should only speculate with money that you can afford to lose. CFD trading may not be suitable for all customers; therefore, please ensure that you fully understand the risks involved and seek independent advice if necessary and prior to entering into such transactions. When trading CFDs with MYBROKER FX, you are merely trading on the outcome of a financial instrument and therefore do not take delivery of any underlying instrument, nor are you entitled to any dividends payable or any other benefits related to the same. MYBROKER FX and its products and services offered on the site www.mybrokerfx.com are NOT registered or regulated by any U.S. or Canadian regulator and not regulated by FINRA, SEC, NFA or CFTC. MYBROKER FX reserves the right to retroactively charge storage fees for positions held for extended period of time as stipulated by the market liquidity provider.
              </p>
              <p className="footer-text Text_container__fiiSE Text_body20__e5y_H mt-4">
                Restricted Regions: MYBROKER FX does not provide services to residents of certain countries, including, but not limited to, Canada, Japan, Democratic People's Republic of Korea (DPRK), Iran, and the United States of America; or any person in any country or jurisdiction where such distribution or use would be contrary to local laws or regulations.
              </p>
            </div>
            <div className="col-md-3">
              <div className="footer-img-block text-center">
                <img src="/assets/home/img/logobanner.png" className="footer-logo img-responsive" />
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom-navbar">
          <button className="a-btn" onClick={(e) => { showTermsConditions() }} >Terms & Conditions</button>
          <button className="a-btn" onClick={(e) => { showPrivacyPolicy() }} >Privacy Policy</button>
          <a href={URL_LOGIN}>Login</a>
          <a href={URL_FORGOT_PASSWORD}>Forgot Your Password?</a>
        </div>
        <div className="footer-banner">
          <p>© 2021 All rights reserved - My Broker FX</p>
        </div>
      </div>

      <TermsConditionsModal
        modalTitle={`Terms and Conditions`}
        isVisible={visibleTermsConditionsModal}
        setVisibleModal={setVisibleTermsConditionsModal}
        modalClass="home-page terms-conditions-modal"
      />

      <PrivacyPolicyModal
        modalTitle={`Notice of privacy`}
        isVisible={visiblePrivacyPolicyModal}
        setVisibleModal={setVisiblePrivacyPolicyModal}
        modalClass="home-page privacy-policy-modal"
      />

    </div>
  );
};

export default HomeFooter;
