// any app specific constants that you don't want to keep in config can go here.
export const APP_NAME = "My Broker FX";
export const REGISTER_FUNC = "enabled";

let base_site_url = "";
let base_api_url = "";
let socket_server_url = "";
let base_upload_url = "";

let sms_func = "disabled";

export const BACKEND_LOCATION = "localhost"; //"localhost", "prod"
if (BACKEND_LOCATION === "localhost") {
    base_site_url = "http://localhost"
    base_api_url = base_site_url + ":8088"
    socket_server_url = base_api_url
    base_upload_url = base_site_url + ""
    sms_func = "disabled";
} else if (BACKEND_LOCATION === "prod") {
    base_site_url = "https://higherlevelfx.com"
    base_api_url = "https://prodapi.higherlevelfx.com"
    socket_server_url = base_api_url
    base_upload_url = base_site_url
    sms_func = "enabled";
}

export const BASE_SITE_URL = base_site_url
export const BASE_FRONT_URL = BASE_SITE_URL
export const BASE_API_URL = base_api_url
export const SOCKET_SERVER_URL = socket_server_url
export const BASE_UPLOAD_URL = base_upload_url

export const SITE_MODE = "live";
export const SMS_FUNC = sms_func;

export const SYSTEM_ERROR = "System error. Please try again later!";

export const CTA_YES = "Yes";
export const CTA_NO = "No";
export const CTA_CANCEL = "Cancel";

export const logoUrl = "/assets/images/logo.png";
export const googleKey = "6LfrFKQUAAAAAMzFobDZ7ZWy982lDxeps8cd1I2i";

export const APPSTORE_LINK = ""

export const USER_TYPE = {
    INDIVIDUAL: 0,
    COMPANY: 1
}

export const URL_LOGIN = "https://myaccount.mybrokerfx.com/login"
export const URL_REGISTER = "https://myaccount.mybrokerfx.com/register"
export const URL_FORGOT_PASSWORD = "https://myaccount.mybrokerfx.com/forgot-password"
