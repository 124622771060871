import {
  ROUTE_COMPANY_REGISTER,
  ROUTE_REGISTER,
  ROUTE_ROOT,
} from "navigation/CONSTANTS";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import { Link, withRouter } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  empty,
  get_data_value,
  isEmpty,
  is_empty,
  showToast,
  show_loading,
  to_array,
  trim_phone,
  ValidateEmail,
  jQuery,
  intval,
  genNumArr,
  is_null,
} from "utils/GlobalFunctions";
import {
  apiCheckAuthSms,
  apiCheckCoupon,
  apiCheckPasswordStrength,
  apiCheckSponsor,
  apiLogin,
  apiLogout,
  apiRegister,
  apiSendAuthSms,
} from "services/loginService";
import { updateUser } from "redux/actions/userActions";
import { countryList } from "utils/countryList";
import CreditCardForm from "components/CreditCardForm/CreditCardForm";
import { REGISTER_FUNC, SMS_FUNC } from "config/CONSTANTS";
import AuthSmsCodeModal from "components/AuthSmsCodeModal/AuthSmsCodeModal";
import { console_log } from "utils/logger";

const IndividualUserDocumentBlock = (props) => {
  const { pageData, setPageData, userName } = props;

  const dispatch = useDispatch();
  useEffect(() => {}, [props]);
  //////////////////////////////////////////////////////////////////
  const userDataStore = useSelector((x) => x.userDataStore);
  let curYear = new Date().getFullYear();
  const initUserData = {
    user_name: "",
    user_first_name: "",
    user_last_name: "",
    user_phone: "",
    user_email: "",
  };
  const [userData, setUserData] = useState(initUserData);
  const [errorField, setErrorField] = useState([]);
  //const [isBusy, setIsBusy] = useState(false);
  const validateFields = () => {
    var errorList = [];
    errorList = isEmpty(userData, "user_name", errorList);
    errorList = isEmpty(userData, "user_first_name", errorList);
    errorList = isEmpty(userData, "user_last_name", errorList);

    setErrorField([...errorList]);
    return errorList.length > 0 || errorField.length > 0 ? false : true;
  };

  const onChangeFormField = (e) => {
    let field_name = e.target.name;
    let field_value = e.target.value;
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    userData[field_name] = field_value;
    setUserData({ ...userData });
    console_log("userData", userData);
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [registrationDate, setRegistrationDate] = useState(new Date());
  const setUserRegistrationDate = (d) => {
    console.log(d);
    setRegistrationDate(d);
  };

  return (
    <div className={`individual-user-document-box main-font-color`}>
      <div className="row">
        <div className="col-md-4">
          <div className="form-group">
            <label>Passport:</label>
            <div className="file-upload-group">
              <input
                type="text"
                className={`form-control file-upload-info hidden`}
                readOnly=""
                placeholder="No file selected"
              />
              <button
                className="file-upload-browse btn btn-file-upload"
                type="button"
              >
                Upload
              </button>
              <input
                type="file"
                name="upload_file"
                className="file-upload-default upload_file"
                data-key="upload_file"
              />
            </div>
          </div>
        </div>
        <div className="col-md-1 ">
          <div className="valign height-100">
            <h2 className="mb-0">OR</h2>
          </div>
        </div>
        <div className="col-md-7">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>ID Front:</label>
                <div className="file-upload-group">
                  <input
                    type="text"
                    className={`form-control file-upload-info hidden`}
                    readOnly=""
                    placeholder="No file selected"
                  />
                  <button
                    className="file-upload-browse btn btn-file-upload"
                    type="button"
                  >
                    Upload
                  </button>
                  <input
                    type="file"
                    name="upload_file"
                    className="file-upload-default upload_file"
                    data-key="upload_file"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>ID Back:</label>
                <div className="file-upload-group">
                  <input
                    type="text"
                    className={`form-control file-upload-info hidden`}
                    readOnly=""
                    placeholder="No file selected"
                  />
                  <button
                    className="file-upload-browse btn btn-file-upload"
                    type="button"
                  >
                    Upload
                  </button>
                  <input
                    type="file"
                    name="upload_file"
                    className="file-upload-default upload_file"
                    data-key="upload_file"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>Utility Statement (Not older than 3 months):</label>
            <div className="file-upload-group">
              <input
                type="text"
                className={`form-control file-upload-info hidden`}
                readOnly=""
                placeholder="No file selected"
              />
              <button
                className="file-upload-browse btn btn-file-upload"
                type="button"
              >
                Upload
              </button>
              <input
                type="file"
                name="upload_file"
                className="file-upload-default upload_file"
                data-key="upload_file"
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Bank Statement (Not older than 3 months):</label>
            <div className="file-upload-group">
              <input
                type="text"
                className={`form-control file-upload-info hidden`}
                readOnly=""
                placeholder="No file selected"
              />
              <button
                className="file-upload-browse btn btn-file-upload"
                type="button"
              >
                Upload
              </button>
              <input
                type="file"
                name="upload_file"
                className="file-upload-default upload_file"
                data-key="upload_file"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(IndividualUserDocumentBlock);
