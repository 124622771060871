import {
  ROUTE_REGISTER,
  ROUTE_ROOT,
  ROUTE_USER_DASHBOARD,
} from "navigation/CONSTANTS";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { isMobile } from "utils/GlobalFunctions";
import { HOME_MENU_ITEMS } from "./HomeMenuItems";

const HomeLogoBar = () => {
  const appDataStore = useSelector((x) => x.appDataStore);
  let is_mobile = isMobile();
  return (
    <div className="logo-bar">
      <header className="landing_header">
        <nav className="navbar navbar-expand-lg navbar-dark">
          <Link className="navbar-brand" to={ROUTE_ROOT}>
            <img
              className="banner-logo"
              src="/assets/home/img/logobanner.png"
              alt="img"
            />
          </Link>
        </nav>
      </header>
    </div>
  );
};

export default HomeLogoBar;
