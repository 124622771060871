import HomeLayout from "layouts/HomeLayout/HomeLayout";
import HomeLogoBar from "layouts/HomeLayout/Includes/HomeLogoBar";
import CompanyUserDocumentBlock from "pages/Home/CompanyRegisterPage/inc/CompanyUserDocumentBlock";
import {
  ROUTE_COMPANY_REGISTER,
  ROUTE_REGISTER,
  ROUTE_ROOT,
} from "navigation/CONSTANTS";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import { Link, withRouter } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  empty,
  get_data_value,
  isEmpty,
  is_empty,
  showToast,
  show_loading,
  to_array,
  trim_phone,
  ValidateEmail,
  jQuery,
} from "utils/GlobalFunctions";
import {
  apiCheckAuthSms,
  apiCheckCoupon,
  apiCheckPasswordStrength,
  apiCheckSponsor,
  apiLogin,
  apiLogout,
  apiRegister,
  apiSendAuthSms,
} from "services/loginService";
import { updateUser } from "redux/actions/userActions";
import { countryList } from "utils/countryList";
import CreditCardForm from "components/CreditCardForm/CreditCardForm";
import { REGISTER_FUNC, SMS_FUNC } from "config/CONSTANTS";
import AuthSmsCodeModal from "components/AuthSmsCodeModal/AuthSmsCodeModal";
import { console_log } from "utils/logger";

const CompanyRegisterPage3 = (props) => {
  const { pageData, setPageData, goRegStep } = props;
  const curStep = 2;

  const dispatch = useDispatch();
  const [showConfirmModal, setShowconfirmModal] = useState(false);

  useEffect(() => {}, [props]);
  const showTwoStepCodeModal = () => {
    setShowconfirmModal(true);
  };

  //////////////////////////////////////////////////////////////////

  const userDataStore = useSelector((x) => x.userDataStore);
  let curYear = new Date().getFullYear();
  const initUserData = {
    user_name: "",
    user_first_name: "",
    user_last_name: "",
    user_phone: "",
    user_email: "",
    user_confirm_email: "",
    user_password: "",
    user_password1: "",
  };
  const [userData, setUserData] = useState(initUserData);
  const [errorField, setErrorField] = useState([]);
  //const [isBusy, setIsBusy] = useState(false);

  const validateFields = () => {
    var errorList = [];
    errorList = isEmpty(userData, "user_name", errorList);
    errorList = isEmpty(userData, "user_first_name", errorList);
    errorList = isEmpty(userData, "user_last_name", errorList);
    errorList = isEmpty(userData, "user_phone", errorList);
    errorList = isEmpty(userData, "user_email", errorList);
    errorList = isEmpty(userData, "user_confirm_email", errorList);

    setErrorField([...errorList]);
    return errorList.length > 0 || errorField.length > 0 ? false : true;
  };

  const onChangeFormField = (e) => {
    let field_name = e.target.name;
    let field_value = e.target.value;
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    userData[field_name] = field_value;
    setUserData({ ...userData });
    if (field_name === "user_password") {
      checkPasswordStrength(field_value);
    }
  };
  const onChangeFormCheckboxField = (e) => {
    let field_name = e.target.name;
    let checked = e.target.checked;
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    userData[field_name] = checked;
    setUserData({ ...userData });
  };
  const onChangePhoneField = (value, country, e, formattedValue) => {
    let phone_number = trim_phone(formattedValue);
    if (errorField.includes("user_phone")) {
      let errors = errorField.filter((x) => x !== "user_phone");
      setErrorField([...errors]);
    }
    userData["user_phone"] = phone_number;
    setUserData({ ...userData });
  };
  const onChangeUserType = (e, user_type) => {
    userData["user_type"] = user_type;
    setUserData({ ...userData });
  };
  const submitModalData = (codeData) => {
    let codeStr = to_array(codeData).join("");
    show_loading(true);
    apiCheckAuthSms(userData, codeStr)
      .then((data) => {
        show_loading(false);
        if (data.status === "1") {
          doRegister();
        } else {
          showToast(data.message, "error");
        }
      })
      .catch((err) => {
        show_loading(false);
        showToast(err, "error");
      });
  };
  const onClickRegister = () => {
    let is_valid = validateFields();
    if (is_valid) {
      if (userData["user_email"] !== userData["user_confirm_email"]) {
        showToast("Invalid or not matching email address", "error");
        return false;
      }
      if (!ValidateEmail(userData["user_confirm_email"])) {
        showToast("Invalid or not matching email address", "error");
        return false;
      }
      if (userData["user_password"] !== userData["user_password1"]) {
        showToast("Password must match!", "error");
        return false;
      }
      if (!userData["agree_terms_condition"]) {
        showToast("Please agree to the terms and conditions", "error");
        return false;
      }
      if (!userData["watched_compliance"]) {
        showToast("Please watch the compliance first", "error");
        return false;
      }
      if (!userData["trial_policy"]) {
        showToast("Please agree to the 7-Day trial policy", "error");
        return false;
      }
      if (SMS_FUNC === "disabled") {
        //if test mode then register directly
        doRegister();
      } else {
        //if live mode then first verify phone number
        show_loading(true);
        apiSendAuthSms(userData)
          .then((data) => {
            show_loading(false);
            if (data.status === "1") {
              showToast(data.message, "success");
              showTwoStepCodeModal();
            } else {
              showToast(data.message, "error");
            }
          })
          .catch((err) => {
            show_loading(false);
            showToast(err, "error");
          });
      }
    } else {
      window.scrollTo(0, 0); //
    }
  };
  const doRegister = () => {
    show_loading(true);
  };

  const doLogout = () => {
    if (empty(userDataStore["token"])) {
      return false;
    }

    apiLogout(userDataStore["token"])
      .then((api_res) => {})
      .catch((err) => {});
    dispatch(updateUser({ token: "" }));
  };
  const checkPasswordStrength = (password) => {
    if (password === "") {
      return false;
    }
    apiCheckPasswordStrength(password)
      .then((data) => {
        if (data.status === "1") {
          setPasswdStrengthGood(true);
        } else {
          setPasswdStrengthGood(false);
          setPasswdStrengthErrMessage(data.message);
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  const [passwdStrengthGood, setPasswdStrengthGood] = useState(true);
  const [passwdStrengthErrMessage, setPasswdStrengthErrMessage] = useState("");

  const [couponName, setCouponName] = useState("");
  const [couponApplied, setCouponApplied] = useState(false);
  const [couponAppliedTxt, setCouponAppliedTxt] = useState("");
  const [couponChecking, setCouponChecking] = useState(false);
  const onChangeCouponName = (e) => {
    let field_value = e.target.value;
    setCouponName(field_value);
  };
  const onClickCheckCoupon = () => {
    if (couponChecking) {
      return false;
    }
    if (couponName === "") {
      setTimeout(function () {
        jQuery("#coupon-name").focus();
      }, 100);
      return false;
    }
    if (userData["user_type"] == "1") {
      showToast("You must choose gold package", "error");
      setCouponApplied(false);
      return false;
    }
    setCouponChecking(true);
    setTimeout(function () {
      doCheckCoupon();
    }, 500);
  };
  const doCheckCoupon = () => {
    apiCheckCoupon({ coupon: couponName })
      .then((data) => {
        setCouponChecking(false);
        if (data.status === "1") {
          setCouponApplied(true);
          setCouponAppliedTxt(data.message);
          showToast(data.message, "success");
        } else {
          setCouponApplied(false);
          showToast(data.message, "error");
        }
      })
      .catch((err) => {
        setCouponChecking(false);
        showToast(err, "error");
      });
  };

  //////////////////////////////////////////////////////////////////////////////////
  const [registrationDate, setRegistrationDate] = useState(new Date());
  const setUserRegistrationDate = (d) => {
    console.log(d);
    setRegistrationDate(d);
  };

  const nextRegStep = () => {
    console_log("Complete registration");
  };

  const prevRegStep = () => {
    if (curStep > 0) {
      goRegStep(curStep - 1);
    }
  };

  return (
    <div className="reg-form">
      <div className="row">
        <div className="col-md-12 main-font-color">
          <h6 className="main-font-color">Documents:</h6>
          <p className="text-center">
            In order to activate your account, My Broker FX requires
            identification documents. Please upload the shareholders and
            directors identification documents using the "Select files" button.
          </p>
          <div>
            <b>IMPORTANT :</b>&nbsp; The name and address on ALL documents must
            match and be clearly visible with all 4 corners showing (address
            does not apply with passwords). The Proof of Address and the Bank
            Statement cannot be older than 3 months. We accept PDF, PNG, JPEG
            file formats and they cannot exceed 2MB.
          </div>
        </div>
        <div className="col-md-12">
          <div className="company-user-document-list mt-4">
            <CompanyUserDocumentBlock
              pageData={pageData}
              setPageData={setPageData}
              userName="Aric Mussman"
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="custom-checkbox form-group">
            <input
              type="checkbox"
              id="watched-compliance"
              name="watched_compliance"
              onChange={(e) => {
                onChangeFormCheckboxField(e);
              }}
            />
            <label htmlFor="watched-compliance">
              &nbsp;I've read and agreed to the Individual Client Agreement               
            </label>
          </div>
        </div>

        <div className="col-md-12">
          <div className="reg-btn-group text-center">
            <button
              className="btn btn-danger btn-reg-next"
              type="button"
              onClick={(e) => {
                nextRegStep();
              }}
            >
              Complete Registration
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(CompanyRegisterPage3);
