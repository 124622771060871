import HomeLayout from "layouts/HomeLayout/HomeLayout";
import HomeLogoBar from "layouts/HomeLayout/Includes/HomeLogoBar";
import IndividualRegisterPage0 from "pages/Home/IndividualRegisterPage/IndividualRegisterPage0";

import { ROUTE_REGISTER, ROUTE_ROOT, ROUTE_COMPANY_REGISTER } from "navigation/CONSTANTS";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import { withRouter } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";

import { empty, intval, is_empty, showToast } from "utils/GlobalFunctions";
import { apiLogout } from "services/loginService";
import { updateUser } from "redux/actions/userActions";
import { REGISTER_FUNC } from "config/CONSTANTS";
import { console_log } from "utils/logger";

const IndividualRegisterPage = (props) => {
  const query_params = new URLSearchParams(props.location.search);

  const ref_id = query_params.get("ref");
  console_log("---------ref_id--------", ref_id);
  const default_sponsor_name = empty(ref_id) ? "Admin" : ref_id;
  const [defaultSponsorName, setDefaultSponsorName] =
    useState(default_sponsor_name);
  const [sponsorName, setSponsorName] = useState(default_sponsor_name);

  const dispatch = useDispatch();
  const [showConfirmModal, setShowconfirmModal] = useState(false);
  const initPage = () => {
    let page_title = "Register as Individual";
    dispatch(
      UpdateAppData({
        currentPageTitle: page_title,
        current_route: ROUTE_REGISTER,
        license_cancelled_message: "",
      })
    );
  };

  const [pageData, setPageData] = useState({});
  const [regStep, setRegStep] = useState(0);

  useEffect(() => {
    if (REGISTER_FUNC === "disabled") {
      showToast(
        "Registration temporarily disabled! Please check back later",
        "error"
      );
      props.history.replace({ pathname: ROUTE_ROOT });
    }
    initPage();
    doLogout();

    let step = props.match.params.step;
    if (is_empty(step)) {
      step = 0;
    }else{
      step = intval(step)
    }
    setRegStep(step);
  }, [props]);

  //////////////////////////////////////////////////////////////////
  const userDataStore = useSelector((x) => x.userDataStore);
  const doLogout = () => {
    if (empty(userDataStore["token"])) {
      return false;
    }
    apiLogout(userDataStore["token"])
      .then((api_res) => {})
      .catch((err) => {});
    dispatch(updateUser({ token: "" }));
  };
  const goRegStep = (step) => {
    setRegStep(step);
    console_log('step', step)
    props.history.push({ pathname: ROUTE_COMPANY_REGISTER + "/" + step }); //for tmp

    document.documentElement.style.scrollBehavior = "auto";
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <HomeLayout>
        <div className="landing-page-section landing_black">
          <div className="landing_container bg-img">
            <HomeLogoBar></HomeLogoBar>

            <div className="container">
              <div className="form-container registratoin-form-container">
                <form className="reg-form bg-gradient-1" method="post">
                  <div className="reg-form-content bg-overlayer">
                    <h3 className="text-center text-white mt-0 mb-4">
                      Registration
                    </h3>
                    {regStep === 0 && (
                      <IndividualRegisterPage0
                        pageData={pageData}
                        setPageData={setPageData}
                        goRegStep={goRegStep}
                      />
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </HomeLayout>
    </div>
  );
};

export default withRouter(IndividualRegisterPage);
