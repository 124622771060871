import HomeLayout from "layouts/HomeLayout/HomeLayout";
import HomeLogoBar from "layouts/HomeLayout/Includes/HomeLogoBar";
import CompanyShareHolderList from "pages/Home/CompanyRegisterPage/inc/CompanyShareHolderList";
import CompanyDirectorList from "pages/Home/CompanyRegisterPage/inc/CompanyDirectorList";

import {
  ROUTE_COMPANY_REGISTER,
  ROUTE_REGISTER,
  ROUTE_ROOT,
} from "navigation/CONSTANTS";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import { Link, withRouter } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  empty,
  get_data_value,
  isEmpty,
  is_empty,
  showToast,
  show_loading,
  to_array,
  trim_phone,
  ValidateEmail,
  jQuery,
  intval,
  genNumArr,
} from "utils/GlobalFunctions";
import {
  apiCheckAuthSms,
  apiCheckCoupon,
  apiCheckPasswordStrength,
  apiCheckSponsor,
  apiLogin,
  apiLogout,
  apiRegister,
  apiSendAuthSms,
} from "services/loginService";
import { updateUser } from "redux/actions/userActions";
import { countryList } from "utils/countryList";
import CreditCardForm from "components/CreditCardForm/CreditCardForm";
import { REGISTER_FUNC, SMS_FUNC } from "config/CONSTANTS";
import AuthSmsCodeModal from "components/AuthSmsCodeModal/AuthSmsCodeModal";
import { console_log } from "utils/logger";

const CompanyRegisterPage1 = (props) => {
  const { pageData, setPageData, goRegStep } = props;
  const curStep = 1;
  const boxCount = 4;
  let boxDropdownOptionList = genNumArr(boxCount);

  const initUserData = {
    user_name: "",
    share_holder_count: 1,
    director_count: 1,
  };

  const dispatch = useDispatch();
  useEffect(() => {
    setPageData({ ...pageData, 1: { ...initUserData } });
  }, []);
  //////////////////////////////////////////////////////////////////
  const userDataStore = useSelector((x) => x.userDataStore);

  const [userData, setUserData] = useState(initUserData);
  //pageData[1] = { ...initUserData }

  const [errorField, setErrorField] = useState([]);
  //const [isBusy, setIsBusy] = useState(false);
  const validateFields = () => {
    var errorList = [];
    errorList = isEmpty(userData, "user_name", errorList);
    errorList = isEmpty(userData, "user_first_name", errorList);
    errorList = isEmpty(userData, "user_last_name", errorList);
    errorList = isEmpty(userData, "user_phone", errorList);
    errorList = isEmpty(userData, "user_email", errorList);
    errorList = isEmpty(userData, "user_confirm_email", errorList);
    errorList = isEmpty(userData, "user_password", errorList);
    errorList = isEmpty(userData, "user_password1", errorList);
    errorList = isEmpty(userData, "user_type", errorList);
    errorList = isEmpty(userData, "billing_street", errorList);
    errorList = isEmpty(userData, "billing_city", errorList);
    errorList = isEmpty(userData, "billing_state", errorList);
    errorList = isEmpty(userData, "billing_zip_code", errorList);
    errorList = isEmpty(userData, "billing_country", errorList);
    errorList = isEmpty(userData, "owner", errorList);
    errorList = isEmpty(userData, "card_number", errorList);
    errorList = isEmpty(userData, "cvv", errorList);
    errorList = isEmpty(userData, "exp_month", errorList);
    errorList = isEmpty(userData, "exp_year", errorList);
    errorList = isEmpty(userData, "card_is_valid", errorList);
    errorList = isEmpty(userData, "agree_terms_condition", errorList);
    errorList = isEmpty(userData, "watched_compliance", errorList);
    errorList = isEmpty(userData, "trial_policy", errorList);

    setErrorField([...errorList]);
    return errorList.length > 0 || errorField.length > 0 ? false : true;
  };

  const onChangeFormField = (e) => {
    let field_name = e.target.name;
    let field_value = e.target.value;
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    userData[field_name] = field_value;
    setUserData({ ...userData });
    setPageData({ ...pageData, 1: { ...userData } });   
    console_log("userData", userData);
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [registrationDate, setRegistrationDate] = useState(new Date());
  const setUserRegistrationDate = (d) => {
    console.log(d);
    setRegistrationDate(d);
  };
  const nextRegStep = () => {
    goRegStep(curStep + 1);
  };
  const prevRegStep = () => {
    if (curStep > 0) {
      goRegStep(curStep - 1);
    }
  };

  return (
    <div className="reg-form">
      <h6 className="box-title text-normal-spacing mt-3 mb-20 main-font-color">
        Company Shareholders & Directors:
      </h6>
      <div className="row">
        <div className="col-md-6">
          <div className="shareholders-wrapper">
            <div className="form-group">
              <label>Number of Shareholders:</label>
              <select
                id="share_holder_count"
                name="share_holder_count"
                className={
                  "form-control appearence" +
                  (errorField.includes("share_holder_count")
                    ? " is-invalid"
                    : "")
                }
                onChange={(e) => {
                  onChangeFormField(e);
                }}
                value={get_data_value(userData, "share_holder_count", "1")}
              >
                {boxDropdownOptionList.map((optionItem, index) => (
                  <option key={index} value={optionItem}>
                    {optionItem}
                  </option>
                ))}
              </select>
            </div>

            <CompanyShareHolderList
              pageData={pageData}
              setPageData={setPageData}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="directors-wrapper">
            <div className="form-group">
              <label>Number of Directors:</label>
              <select
                id="director_count"
                name="director_count"
                className={
                  "form-control appearence" +
                  (errorField.includes("director_count") ? " is-invalid" : "")
                }
                onChange={(e) => {
                  onChangeFormField(e);
                }}
                value={get_data_value(userData, "director_count", "1")}
              >
                {boxDropdownOptionList.map((optionItem, index) => (
                  <option key={index} value={optionItem}>
                    {optionItem}
                  </option>
                ))}
              </select>
            </div>

            <CompanyDirectorList
              pageData={pageData}
              setPageData={setPageData}
            />
          </div>
        </div>

        <div className="col-md-12">
          <div className="reg-btn-group reg-btn-group-full  text-center">
            <button
              className="btn btn-danger btn-reg-prev"
              type="button"
              onClick={(e) => {
                prevRegStep();
              }}
            >
              Back
            </button>
            <button
              className="btn btn-danger btn-reg-next"
              type="button"
              onClick={(e) => {
                nextRegStep();
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(CompanyRegisterPage1);
