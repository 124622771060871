import HomeLayout from "layouts/HomeLayout/HomeLayout";
import HomeLogoBar from "layouts/HomeLayout/Includes/HomeLogoBar";
import IndividualUserDocumentBlock from "pages/Home/IndividualRegisterPage/inc/IndividualUserDocumentBlock";

import {
  ROUTE_COMPANY_REGISTER,
  ROUTE_REGISTER,
  ROUTE_ROOT,
} from "navigation/CONSTANTS";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import { Link, withRouter } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  empty,
  get_data_value,
  isEmpty,
  is_empty,
  showToast,
  show_loading,
  to_array,
  trim_phone,
  ValidateEmail,
  jQuery,
} from "utils/GlobalFunctions";
import {
  apiCheckAuthSms,
  apiCheckCoupon,
  apiCheckPasswordStrength,
  apiCheckSponsor,
  apiLogin,
  apiLogout,
  apiRegister,
  apiSendAuthSms,
} from "services/loginService";
import { updateUser } from "redux/actions/userActions";
import { countryList } from "utils/countryList";
import CreditCardForm from "components/CreditCardForm/CreditCardForm";
import { REGISTER_FUNC, SMS_FUNC } from "config/CONSTANTS";
import AuthSmsCodeModal from "components/AuthSmsCodeModal/AuthSmsCodeModal";
import { console_log } from "utils/logger";

const IndividualRegisterPage0 = (props) => {
  const { pageData, setPageData, goRegStep } = props;
  const curStep = 0;

  const dispatch = useDispatch();
  const [showConfirmModal, setShowconfirmModal] = useState(false);

  useEffect(() => {}, [props]);
  const showTwoStepCodeModal = () => {
    setShowconfirmModal(true);
  };

  //////////////////////////////////////////////////////////////////

  const userDataStore = useSelector((x) => x.userDataStore);
  let curYear = new Date().getFullYear();
  const initUserData = {
    user_name: "",
    user_first_name: "",
    user_last_name: "",
    user_phone: "",
    user_email: "",
    user_confirm_email: "",
    user_password: "",
    user_password1: "",
    user_type: "0",
    billing_street: "",
    billing_city: "",
    billing_state: "",
    billing_zip_code: "",
    billing_country: "US",
    owner: "",
    card_number: "",
    cvv: "",
    exp_month: "01",
    exp_year: curYear - 2000,
    card_type: "",
    card_is_valid: false,
    agree_terms_condition: false,
    watched_compliance: false,
    trial_policy: false,
  };
  const [userData, setUserData] = useState(initUserData);
  const [errorField, setErrorField] = useState([]);
  //const [isBusy, setIsBusy] = useState(false);

  const validateFields = () => {
    var errorList = [];
    errorList = isEmpty(userData, "user_name", errorList);
    errorList = isEmpty(userData, "user_first_name", errorList);
    errorList = isEmpty(userData, "user_last_name", errorList);
    errorList = isEmpty(userData, "user_phone", errorList);
    errorList = isEmpty(userData, "user_email", errorList);
    errorList = isEmpty(userData, "user_confirm_email", errorList);
    errorList = isEmpty(userData, "user_password", errorList);
    errorList = isEmpty(userData, "user_password1", errorList);
    errorList = isEmpty(userData, "user_type", errorList);
    errorList = isEmpty(userData, "billing_street", errorList);
    errorList = isEmpty(userData, "billing_city", errorList);
    errorList = isEmpty(userData, "billing_state", errorList);
    errorList = isEmpty(userData, "billing_zip_code", errorList);
    errorList = isEmpty(userData, "billing_country", errorList);
    errorList = isEmpty(userData, "owner", errorList);
    errorList = isEmpty(userData, "card_number", errorList);
    errorList = isEmpty(userData, "cvv", errorList);
    errorList = isEmpty(userData, "exp_month", errorList);
    errorList = isEmpty(userData, "exp_year", errorList);
    errorList = isEmpty(userData, "card_is_valid", errorList);
    errorList = isEmpty(userData, "agree_terms_condition", errorList);
    errorList = isEmpty(userData, "watched_compliance", errorList);
    errorList = isEmpty(userData, "trial_policy", errorList);

    setErrorField([...errorList]);
    return errorList.length > 0 || errorField.length > 0 ? false : true;
  };

  const onChangeFormField = (e) => {
    let field_name = e.target.name;
    let field_value = e.target.value;
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    userData[field_name] = field_value;
    setUserData({ ...userData });
    if (field_name === "user_password") {
      checkPasswordStrength(field_value);
    }
  };
  const onChangeFormCheckboxField = (e) => {
    let field_name = e.target.name;
    let checked = e.target.checked;
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    userData[field_name] = checked;
    setUserData({ ...userData });
  };
  const onChangePhoneField = (value, country, e, formattedValue) => {
    let phone_number = trim_phone(formattedValue);
    if (errorField.includes("user_phone")) {
      let errors = errorField.filter((x) => x !== "user_phone");
      setErrorField([...errors]);
    }
    userData["user_phone"] = phone_number;
    setUserData({ ...userData });
  };
  const onChangeUserType = (e, user_type) => {
    userData["user_type"] = user_type;
    setUserData({ ...userData });
  };
  const submitModalData = (codeData) => {
    let codeStr = to_array(codeData).join("");
    show_loading(true);
    apiCheckAuthSms(userData, codeStr)
      .then((data) => {
        show_loading(false);
        if (data.status === "1") {
          doRegister();
        } else {
          showToast(data.message, "error");
        }
      })
      .catch((err) => {
        show_loading(false);
        showToast(err, "error");
      });
  };
  const onClickRegister = () => {
    let is_valid = validateFields();
    if (is_valid) {
      if (userData["user_email"] !== userData["user_confirm_email"]) {
        showToast("Invalid or not matching email address", "error");
        return false;
      }
      if (!ValidateEmail(userData["user_confirm_email"])) {
        showToast("Invalid or not matching email address", "error");
        return false;
      }
      if (userData["user_password"] !== userData["user_password1"]) {
        showToast("Password must match!", "error");
        return false;
      }
      if (!userData["agree_terms_condition"]) {
        showToast("Please agree to the terms and conditions", "error");
        return false;
      }
      if (!userData["watched_compliance"]) {
        showToast("Please watch the compliance first", "error");
        return false;
      }
      if (!userData["trial_policy"]) {
        showToast("Please agree to the 7-Day trial policy", "error");
        return false;
      }
      if (SMS_FUNC === "disabled") {
        //if test mode then register directly
        doRegister();
      } else {
        //if live mode then first verify phone number
        show_loading(true);
        apiSendAuthSms(userData)
          .then((data) => {
            show_loading(false);
            if (data.status === "1") {
              showToast(data.message, "success");
              showTwoStepCodeModal();
            } else {
              showToast(data.message, "error");
            }
          })
          .catch((err) => {
            show_loading(false);
            showToast(err, "error");
          });
      }
    } else {
      window.scrollTo(0, 0); //
    }
  };
  const doRegister = () => {
    show_loading(true);
  };

  const doLogout = () => {
    if (empty(userDataStore["token"])) {
      return false;
    }

    apiLogout(userDataStore["token"])
      .then((api_res) => {})
      .catch((err) => {});
    dispatch(updateUser({ token: "" }));
  };
  const checkPasswordStrength = (password) => {
    if (password === "") {
      return false;
    }
    apiCheckPasswordStrength(password)
      .then((data) => {
        if (data.status === "1") {
          setPasswdStrengthGood(true);
        } else {
          setPasswdStrengthGood(false);
          setPasswdStrengthErrMessage(data.message);
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  const [passwdStrengthGood, setPasswdStrengthGood] = useState(true);
  const [passwdStrengthErrMessage, setPasswdStrengthErrMessage] = useState("");

  const [couponName, setCouponName] = useState("");
  const [couponApplied, setCouponApplied] = useState(false);
  const [couponAppliedTxt, setCouponAppliedTxt] = useState("");
  const [couponChecking, setCouponChecking] = useState(false);
  const onChangeCouponName = (e) => {
    let field_value = e.target.value;
    setCouponName(field_value);
  };
  const onClickCheckCoupon = () => {
    if (couponChecking) {
      return false;
    }
    if (couponName === "") {
      setTimeout(function () {
        jQuery("#coupon-name").focus();
      }, 100);
      return false;
    }
    if (userData["user_type"] == "1") {
      showToast("You must choose gold package", "error");
      setCouponApplied(false);
      return false;
    }
    setCouponChecking(true);
    setTimeout(function () {
      doCheckCoupon();
    }, 500);
  };
  const doCheckCoupon = () => {
    apiCheckCoupon({ coupon: couponName })
      .then((data) => {
        setCouponChecking(false);
        if (data.status === "1") {
          setCouponApplied(true);
          setCouponAppliedTxt(data.message);
          showToast(data.message, "success");
        } else {
          setCouponApplied(false);
          showToast(data.message, "error");
        }
      })
      .catch((err) => {
        setCouponChecking(false);
        showToast(err, "error");
      });
  };

  const [registrationDate, setRegistrationDate] = useState(new Date());
  const setUserRegistrationDate = (d) => {
    console.log(d);
    setRegistrationDate(d);
  };

  const yesNoOptions = [
    {
      value: 1,
      text: "Yes",
    },
    {
      value: 0,
      text: "No",
    },
  ];

  const sourceWealthOptions = [
    {
      value: 0,
      text: "Income from profession/emplyment",
    },
    {
      value: 1,
      text: "Company Ownership/Sale of Company Assets",
    },
    {
      value: 2,
      text: "Investments or Savings",
    },
    {
      value: 3,
      text: "Other",
    },
  ];
  const exchangeSecurityYearsExperienceOptions = [
    {
      value: 0,
      text: "None",
    },
    {
      value: 1,
      text: "0-2 years",
    },
    {
      value: 2,
      text: "2-5 years",
    },
    {
      value: 3,
      text: "5-10 years",
    },
    {
      value: 4,
      text: "More than 10 years",
    },
  ];
  const leverageMetalYearsExperienceOptions =
    exchangeSecurityYearsExperienceOptions;
  const commoditiesYearsExperienceOptions =
    exchangeSecurityYearsExperienceOptions;
  const futureYearsExperienceOptions = exchangeSecurityYearsExperienceOptions;

  const leverageMetalFrequencyTradingOptions = [
    {
      value: 0,
      text: "None",
    },
    {
      value: 1,
      text: "Daily",
    },
    {
      value: 2,
      text: "Weekly",
    },
    {
      value: 3,
      text: "Monthly",
    },
    {
      value: 4,
      text: "Quarterly",
    },
    {
      value: 5,
      text: "Semi-annual",
    },
  ];
  const commoditiesFrequencyTradingOptions =
    leverageMetalFrequencyTradingOptions;
  const futureFrequencyTradingOptions = leverageMetalFrequencyTradingOptions;

  const purposeAccountOptions = [
    {
      value: 0,
      text: "Hedging",
    },
    {
      value: 1,
      text: "Speculation",
    },
    {
      value: 2,
      text: "Property",
    },
    {
      value: 3,
      text: "Other",
    },
  ];
  const managementAccountOptions = [
    {
      value: 0,
      text: "Self-Management",
    },
    {
      value: 1,
      text: "Third Party Manager",
    },
    {
      value: 2,
      text: "You Act As A Money Manager",
    },
    {
      value: 3,
      text: "You Are Going To Use E.A. To Manage Your Account",
    },
  ];

  const nextRegStep = () => {
     console_log('complete registration')
  };

  const prevRegStep = () => {
    if (curStep > 0) {
      goRegStep(curStep - 1);
    }
  };

  return (
    <div className="reg-form main-font-color">
      <div className="row">
        <div className="col-md-12">
          <div className="user-info-block">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label>Email:</label>
                  <input
                    type="email"
                    className={
                      "form-control" +
                      (errorField.includes("email") ? " is-invalid" : "")
                    }
                    id="email"
                    name="email"
                    placeholder="Your Email"
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>First Name:</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (errorField.includes("first_name") ? " is-invalid" : "")
                    }
                    id="first_name"
                    name="first_name"
                    placeholder="First Name"
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Last Name:</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (errorField.includes("last_name") ? " is-invalid" : "")
                    }
                    id="last_name"
                    name="last_name"
                    placeholder="Last Name"
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Date of Birth:</label>
                  <DatePicker
                    className={
                      "form-control" +
                      (errorField.includes("dob") ? " is-invalid" : "")
                    }
                    id="dob"
                    name="dob"
                    placeholder="MM/DD/YYYY"
                    dateFormat="MM/dd/yyyy"
                    maxDate={new Date()}
                    minDate={new Date(1950, 1, 1)}
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown={true}
                    showYearDropdown
                    selected={registrationDate}
                    onChange={(d) => {
                      setUserRegistrationDate(d);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Phone Number:</label>
                  <div className="phone-number-box">
                    <PhoneInput
                      country={"us"}
                      value={get_data_value(userData, "user_phone")}
                      placeholder=""
                      onChange={(value, country, e, formattedValue) => {
                        onChangePhoneField(value, country, e, formattedValue);
                      }}
                      inputProps={{
                        type: "tel",
                        className: "form-control phone_number",
                        id: "phone",
                        name: "user_phone",
                        placeholder: "",
                      }}
                    />

                    <input type="hidden" name="phone_number" />
                    <input type="hidden" name="dial_code" />
                    <button
                      className="a-btn btn-remove-tel"
                      onClick={(e) => {
                        onChangePhoneField("", "us", e, "");
                      }}
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="user-address-block" id="user-address-block">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Address:</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (errorField.includes("address") ? " is-invalid" : "")
                    }
                    id="address"
                    name="address"
                    placeholder="Address"
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Country:</label>
                  <select
                    id="country"
                    name="country"
                    className={
                      "form-control" +
                      (errorField.includes("country") ? " is-invalid" : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(userData, "country", "US")}
                  >
                    {countryList.map((countryItem, index) => (
                      <option key={index} value={countryItem.code}>
                        {countryItem.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>City:</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (errorField.includes("city") ? " is-invalid" : "")
                    }
                    id="city"
                    name="city"
                    placeholder="City"
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Zip Code:</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (errorField.includes("zip_code") ? " is-invalid" : "")
                    }
                    id="zip_code"
                    name="zip_code"
                    placeholder="Zip Code"
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="user-personal-block">
            <h6 className="box-title text-normal-spacing mt-3 mb-20 main-font-color">
              Personal:
            </h6>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label>Are you a Political exposed Person? (PEP)</label>
                  <select
                    id="political_exposed_person"
                    name="political_exposed_person"
                    className={
                      "form-control appearence" +
                      (errorField.includes("political_exposed_person")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(
                      userData,
                      "political_exposed_person",
                      ""
                    )}
                  >
                    {/* <option value="">Select</option> */}
                    {yesNoOptions.map((optionItem, index) => (
                      <option key={index} value={optionItem.value}>
                        {optionItem.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>
                    Are you involved in a terrorist cell or any criminal
                    activity?
                  </label>
                  <select
                    id="involved_terrorist"
                    name="involved_terrorist"
                    className={
                      "form-control appearence" +
                      (errorField.includes("involved_terrorist")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(userData, "involved_terrorist", "")}
                  >
                    {/* <option value="">Select</option> */}
                    {yesNoOptions.map((optionItem, index) => (
                      <option key={index} value={optionItem.value}>
                        {optionItem.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Source of wealth:</label>
                  <select
                    id="source_wealth"
                    name="source_wealth"
                    className={
                      "form-control appearence" +
                      (errorField.includes("source_wealth")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(userData, "source_wealth", "")}
                  >
                    {/* <option value="">Select</option> */}
                    {sourceWealthOptions.map((optionItem, index) => (
                      <option key={index} value={optionItem.value}>
                        {optionItem.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-12">
                <p>
                  Decralation: <br />
                  I/We declare (as an authorized signatory for the applicant)
                  that the information provided in this form, is to the best of
                  my knowledge and belief, accurate and complete. I/We further
                  confirm that my/or wealth is from legitimate sources as
                  indicated above, and that I/We will also provide the required
                  evidence of the source of wealth as is required.
                </p>
                <div className="custom-checkbox form-group">
                  <input
                    type="checkbox"
                    id="watched-compliance"
                    name="watched_compliance"
                    onChange={(e) => {
                      onChangeFormCheckboxField(e);
                    }}
                  />
                  <label htmlFor="watched-compliance">&nbsp;Agree</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="user-trading-experience-block">
            <h6 className="box-title text-normal-spacing mt-3 mb-20 main-font-color">
              Trading Experience:
            </h6>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Do you have any previous experience Trading?</label>
                  <select
                    id="previous_experience_trading"
                    name="previous_experience_trading"
                    className={
                      "form-control appearence" +
                      (errorField.includes("previous_experience_trading")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(
                      userData,
                      "previous_experience_trading",
                      ""
                    )}
                  >
                    {/* <option value="">Select</option> */}
                    {yesNoOptions.map((optionItem, index) => (
                      <option key={index} value={optionItem.value}>
                        {optionItem.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label className="mb-0">Exchange Securities:</label>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Years of experience:</label>
                  <select
                    id="exchange_security_years_experience"
                    name="exchange_security_years_experience"
                    className={
                      "form-control appearence" +
                      (errorField.includes("exchange_security_years_experience")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(
                      userData,
                      "exchange_security_years_experience",
                      ""
                    )}
                  >
                    {/* <option value="">Select</option> */}
                    {exchangeSecurityYearsExperienceOptions.map(
                      (optionItem, index) => (
                        <option key={index} value={optionItem.value}>
                          {optionItem.text}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label className="mb-0">
                  Leverage FX, CFD ́s and precious metals
                </label>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Years of experience:</label>
                  <select
                    id="leverage_metals_years_experience"
                    name="leverage_metals_years_experience"
                    className={
                      "form-control appearence" +
                      (errorField.includes("leverage_metals_years_experience")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(
                      userData,
                      "leverage_metals_years_experience",
                      ""
                    )}
                  >
                    {/* <option value="">Select</option> */}
                    {leverageMetalYearsExperienceOptions.map(
                      (optionItem, index) => (
                        <option key={index} value={optionItem.value}>
                          {optionItem.text}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Frequency of trading:</label>
                  <select
                    id="leverage_metals_frequancy_trading"
                    name="leverage_metals_frequancy_trading"
                    className={
                      "form-control appearence" +
                      (errorField.includes("leverage_metals_frequancy_trading")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(
                      userData,
                      "leverage_metals_frequancy_trading",
                      ""
                    )}
                  >
                    {/* <option value="">Select</option> */}
                    {leverageMetalFrequencyTradingOptions.map(
                      (optionItem, index) => (
                        <option key={index} value={optionItem.value}>
                          {optionItem.text}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label className="mb-0">Commodities</label>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Years of experience:</label>
                  <select
                    id="commodities_years_experience"
                    name="commodities_years_experience"
                    className={
                      "form-control appearence" +
                      (errorField.includes("commodities_years_experience")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(
                      userData,
                      "commodities_years_experience",
                      ""
                    )}
                  >
                    {/* <option value="">Select</option> */}
                    {commoditiesYearsExperienceOptions.map(
                      (optionItem, index) => (
                        <option key={index} value={optionItem.value}>
                          {optionItem.text}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Frequency of trading:</label>
                  <select
                    id="commodities_frequancy_trading"
                    name="commodities_frequancy_trading"
                    className={
                      "form-control appearence" +
                      (errorField.includes("commodities_frequancy_trading")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(
                      userData,
                      "commodities_frequancy_trading",
                      ""
                    )}
                  >
                    {/* <option value="">Select</option> */}
                    {commoditiesFrequencyTradingOptions.map(
                      (optionItem, index) => (
                        <option key={index} value={optionItem.value}>
                          {optionItem.text}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label className="mb-0">Futures</label>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Years of experience:</label>
                  <select
                    id="futures_years_experience"
                    name="futures_years_experience"
                    className={
                      "form-control appearence" +
                      (errorField.includes("futures_years_experience")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(
                      userData,
                      "futures_years_experience",
                      ""
                    )}
                  >
                    {/* <option value="">Select</option> */}
                    {futureYearsExperienceOptions.map((optionItem, index) => (
                      <option key={index} value={optionItem.value}>
                        {optionItem.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Frequency of trading:</label>
                  <select
                    id="futures_frequancy_trading"
                    name="futures_frequancy_trading"
                    className={
                      "form-control appearence" +
                      (errorField.includes("futures_frequancy_trading")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(
                      userData,
                      "futures_frequancy_trading",
                      ""
                    )}
                  >
                    {/* <option value="">Select</option> */}
                    {futureFrequencyTradingOptions.map((optionItem, index) => (
                      <option key={index} value={optionItem.value}>
                        {optionItem.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label className="mb-0">Account</label>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Purpose of the account:</label>
                  <select
                    id="purpose_account"
                    name="purpose_account"
                    className={
                      "form-control appearence" +
                      (errorField.includes("purpose_account")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(userData, "purpose_account", "")}
                  >
                    {/* <option value="">Select</option> */}
                    {purposeAccountOptions.map((optionItem, index) => (
                      <option key={index} value={optionItem.value}>
                        {optionItem.text}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="block">
                  <div className="form-group">
                    <input
                      type="email"
                      className={
                        "form-control" +
                        (errorField.includes("email") ? " is-invalid" : "")
                      }
                      id="email"
                      name="email"
                      placeholder="Other"
                      readOnly={true}
                      onChange={(e) => {
                        onChangeFormField(e);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Management of the account:</label>
                  <select
                    id="manage_account"
                    name="manage_account"
                    className={
                      "form-control appearence" +
                      (errorField.includes("manage_account")
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      onChangeFormField(e);
                    }}
                    value={get_data_value(userData, "manage_account", "")}
                  >
                    {/* <option value="">Select</option> */}
                    {managementAccountOptions.map((optionItem, index) => (
                      <option key={index} value={optionItem.value}>
                        {optionItem.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12 ">
              <h6 className="main-font-color">Documents:</h6>
              <p className="text-center">
                In order to activate your account, My Broker FX requires
                identification documents. Please upload the shareholders and
                directors identification documents using the "Select files"
                button.
              </p>
              <div>
                <b>IMPORTANT :</b>&nbsp; The name and address on ALL documents
                must match and be clearly visible with all 4 corners showing
                (address does not apply with passwords). The Proof of Address
                and the Bank Statement cannot be older than 3 months. We accept
                PDF, PNG, JPEG file formats and they cannot exceed 2MB.
              </div>
            </div>
            <div className="col-md-12">
              <div className="company-user-document-list mt-4">
                <IndividualUserDocumentBlock
                  pageData={pageData}
                  setPageData={setPageData}
                  userName="Aric Mussman"
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="custom-checkbox form-group">
                <input
                  type="checkbox"
                  id="watched-compliance1"
                  name="watched_compliance1"
                  onChange={(e) => {
                    onChangeFormCheckboxField(e);
                  }}
                />
                <label htmlFor="watched-compliance1">
                  &nbsp;I've read and agreed to the Individual Client Agreement
                </label>
              </div>
            </div>             
          </div>
        </div>

        <div className="col-md-12">
          <div className="reg-btn-group text-center">
            <button
              className="btn btn-danger btn-reg-next"
              type="button"
              onClick={(e) => {
                nextRegStep();
              }}
            >
              Complete Registration
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(IndividualRegisterPage0);
