import HomeLayout from "layouts/HomeLayout/HomeLayout";
import {
  ROUTE_ADMIN_DASHBOARD,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_ROOT,
  ROUTE_LOGIN,
  ROUTE_REGISTER,
  ROUTE_USER_DASHBOARD,
} from "navigation/CONSTANTS";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import ReCAPTCHA from "react-google-recaptcha";
import { URL_LOGIN, googleKey } from "config/CONSTANTS";
import { Link, withRouter } from "react-router-dom";
import TwoFactorCodeModal from "components/TwoFactorCodeModal/TwoFactorCodeModal";
import HomeLogoBar from "layouts/HomeLayout/Includes/HomeLogoBar";
import {
  empty,
  get_data_value,
  isEmpty,
  is_empty,
  showToast,
  show_loading,
  to_array,
} from "utils/GlobalFunctions";
import { console_log } from "utils/logger";
import {
  apiCheckTwoFactAuth,
  apiLogin,
  apiLoginTwoFactAuth,
  apiLogout,
} from "services/loginService";
import { updateUser } from "redux/actions/userActions";

const LoginPage = (props) => {
  const dispatch = useDispatch();
  const [showConfirmModal, setShowconfirmModal] = useState(false);
  const initPage = () => {
    let page_title = "Login";
    dispatch(
      UpdateAppData({
        currentPageTitle: page_title,
        current_route: ROUTE_LOGIN,
      })
    );
  };

  useEffect(() => {
    window.location.href = URL_LOGIN

    // initPage();
    // doLogout();

  }, []);

  const showTwoStepCodeModal = () => {
    setShowconfirmModal(true);
  };

  //////////////////////////////////////////////////////////////////
  const userDataStore = useSelector((x) => x.userDataStore);
  const userInfo = userDataStore;
  console_log("userInfo", userInfo);
  const initUserData = { user_name: "", user_password: "" };
  const [userData, setUserData] = useState(initUserData);
  const [errorField, setErrorField] = useState([]);
  const [isBusy, setIsBusy] = useState(false);

  const getLoginAttemptCount = () => {
    let login_attempt_count = get_data_value(userInfo, "login_attempt_count");
    if (is_empty(login_attempt_count)) {
      login_attempt_count = 0;
    } else {
      login_attempt_count = parseInt(login_attempt_count);
    }
    return login_attempt_count;
  };
  const checkCaptchaEnabled = () => {
    let login_attempt_count = getLoginAttemptCount();
    if (login_attempt_count >= 3) {
      return true;
    } else {
      return false;
    }
  };
  const [recaptchaEnable, setRecaptchaEnable] = useState(checkCaptchaEnabled());
  const updateLoginAttemptCount = (count) => {
    if (count === null || count === undefined) {
      count = getLoginAttemptCount();
      count++;
    }
    userInfo["login_attempt_count"] = count;
    dispatch(updateUser({ login_attempt_count: count }));
    setRecaptchaEnable(checkCaptchaEnabled());
  };

  const [recaptchaValid, setRecaptchaValid] = useState(false);
  const reCaptchaChanged = (value) => {
    console_log("reCaptchaChanged", value);
    setRecaptchaValid(true);
  };

  let captcha;
  const setCaptchaRef = (ref) => {
    if (ref) {
      return (captcha = ref);
    }
  };
  const resetCaptcha = () => {
    // maybe set it till after is submitted
    captcha.reset();
  };

  const validateFields = () => {
    var errorList = Array();
    errorList = isEmpty(userData, "user_name", errorList);
    errorList = isEmpty(userData, "user_password", errorList);
    setErrorField([...errorList]);
    return errorList.length > 0 ? false : true;
  };
  const onChangeFormField = (e, field_name) => {
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    userData[field_name] = e.target.value;
    setUserData({ ...userData });
  };
  const submitModalData = (codeData) => {
    let codeStr = to_array(codeData).join("");
    console_log("codeStr", codeStr);
    show_loading(true);
    apiLoginTwoFactAuth(userData, codeStr)
      .then((api_res) => {
        if (api_res.status === "1") {
          doLogin(api_res.data);
        } else {
          show_loading(false);
          showToast(api_res.message, "error");
        }
      })
      .catch((err) => {
        show_loading(false);
        showToast(err, "error");
      });
  };
  const onClickLogin = () => {
    return false;
    let is_valid = validateFields();
    console_log("is valid", is_valid);
    if (is_valid) {
      if (recaptchaEnable) {
        if (!recaptchaValid) {
          showToast("Please confirm that you're not a robot", "error");
          return false;
        }
      }
      show_loading(true);
      apiLogin(userData)
        .then((api_res) => {
          console_log("api_res", api_res);
          if (api_res.status === "1") {
            let data = api_res["data"];
            if (!empty(data["2fa_secret"]) && data["2fa_secret"] !== "") {
              show_loading(false);
              showTwoStepCodeModal();
            } else {
              doLogin(data);
            }
          } else {
            show_loading(false);
            showToast(api_res.message, "error");
            updateLoginAttemptCount();
            if (recaptchaEnable) {
              setRecaptchaValid(false);
              resetCaptcha();
            }
          }
        })
        .catch((err) => {
          show_loading(false);
          showToast(err, "error");
          if (recaptchaEnable) {
            setRecaptchaValid(false);
            resetCaptcha();
          }
        });
    }
  };
  const doLogin = (data) => {
    showToast("You are in!", "success");
    dispatch(updateUser(data));
    setTimeout(() => {
      show_loading(false);
      if (data["is_admin"] === "1") {
        props.history.replace({ pathname: ROUTE_ADMIN_DASHBOARD });
      } else {
        props.history.replace({ pathname: ROUTE_USER_DASHBOARD });
      }
    }, 500);
    updateLoginAttemptCount(0);
  };
  const doLogout = () => {
    if (empty(userDataStore["token"])) {
      return false;
    }

    apiLogout(userDataStore["token"])
      .then((api_res) => {
        console_log("api_res", api_res);
      })
      .catch((err) => { });
    dispatch(updateUser({ token: "" }));
  };
  const onChangeKeyInput = (e, field_name) => {
    if (field_name === "user_password") {
      if (e.key === "Enter") {
        onClickLogin();
      }
    }
  };

  return (
    <div>
      {/* <HomeLayout>
        <div className="landing-page-section landing_black">
          <div className="landing_container bg-img">
            <HomeLogoBar></HomeLogoBar>

            <div className="container">
              <div className="login-form-container">
                <form className="login-form bg-gradient-1" method="post">
                  <div className="login-form-content">
                    <h3 className="text-center text-white mt-0 mb-4">Login</h3>
                    <div className="form-group">
                      <label htmlFor="user_name" className="text-white">
                        Email:
                      </label>
                      <input
                        type="text"
                        id="user_name"
                        name="user_name"
                        className={`form-control ${errorField.includes("user_name") ? "is-invalid" : ""
                          }`}
                        placeholder="Your Email"
                        value={get_data_value(userData, "user_name")}
                        onChange={(e) => {
                          onChangeFormField(e, "user_name");
                        }}
                        onKeyDown={(e) => {
                          onChangeKeyInput(e, "user_name");
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="user_password" className="text-white">
                        Password:
                      </label>
                      <input
                        type="password"
                        id="user_password"
                        name="user_password"
                        className={`form-control ${errorField.includes("user_password")
                          ? "is-invalid"
                          : ""
                          }`}
                        placeholder="Your Password"
                        value={get_data_value(userData, "user_password")}
                        onChange={(e) => {
                          onChangeFormField(e, "user_password");
                        }}
                        onKeyDown={(e) => {
                          onChangeKeyInput(e, "user_password");
                        }}
                      />
                    </div>
                    <div
                      className={`form-group recaptcha-wrap ${recaptchaEnable ? "" : "hidden"
                        }`}
                    >
                      <ReCAPTCHA
                        ref={(r) => setCaptchaRef(r)}
                        sitekey={googleKey}
                        onChange={reCaptchaChanged}
                      ></ReCAPTCHA>
                    </div>
                    <div className="form-group text-center padding-top-10">
                      <input type="hidden" name="action" value="submit" />
                      <button
                        className="btn btn-danger btn-login"
                        type="button"
                        style={{ minWidth: "80px" }}
                        onClick={onClickLogin}
                      >
                        Login
                      </button>
                    </div>
                    <div className="form-group text-center">
                      <div className="row">
                        <div className="col-md-12 reset-password-btn-wrapper">
                          <Link
                            className="btn-reset-password text-white no-underline"
                            to={ROUTE_FORGOT_PASSWORD}
                          >
                            Forgot Your Password?
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </HomeLayout> */}
    </div>
  );
};

export default withRouter(LoginPage);
