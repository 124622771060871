import {
  ROUTE_COMPANY_REGISTER,
  ROUTE_REGISTER,
  ROUTE_ROOT,
} from "navigation/CONSTANTS";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppData } from "redux/actions/appActions";
import { Link, withRouter } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  empty,
  get_data_value,
  isEmpty,
  is_empty,
  showToast,
  show_loading,
  to_array,
  trim_phone,
  ValidateEmail,
  jQuery,
  intval,
  genNumArr,
  is_null,
} from "utils/GlobalFunctions";
import {
  apiCheckAuthSms,
  apiCheckCoupon,
  apiCheckPasswordStrength,
  apiCheckSponsor,
  apiLogin,
  apiLogout,
  apiRegister,
  apiSendAuthSms,
} from "services/loginService";
import { updateUser } from "redux/actions/userActions";
import { countryList } from "utils/countryList";
import CreditCardForm from "components/CreditCardForm/CreditCardForm";
import { REGISTER_FUNC, SMS_FUNC } from "config/CONSTANTS";
import AuthSmsCodeModal from "components/AuthSmsCodeModal/AuthSmsCodeModal";
import { console_log } from "utils/logger";

const CompanyUserBlock = (props) => {
  const { pageData, setPageData, boxNum, boxClass, boxName } = props;

  const dispatch = useDispatch();
  useEffect(() => {
    if(boxNum > 0 && !is_null(pageData) && !is_null(pageData[1]) && !is_null(pageData[1]['share_holder_list'])) {
      console_log("pageData[1]['share_holder_list'][boxNum]", pageData[1]['share_holder_list'][boxNum])
      setUserData(pageData[1]['share_holder_list'][boxNum]);
    }
  }, [props]);
  //////////////////////////////////////////////////////////////////
  const userDataStore = useSelector((x) => x.userDataStore);
  let curYear = new Date().getFullYear();
  const initUserData = {
    user_name: "",
    user_first_name: "",
    user_last_name: "",
    user_phone: "",
    user_email: "",
    user_confirm_email: "",
    user_password: "",
    user_password1: "",
    user_type: "0",
    billing_street: "",
    billing_city: "",
    billing_state: "",
    billing_zip_code: "",
    billing_country: "US",
    owner: "",
    card_number: "",
    cvv: "",
    exp_month: "01",
    exp_year: curYear - 2000,
    card_type: "",
    card_is_valid: false,
    agree_terms_condition: false,
    watched_compliance: false,
    trial_policy: false,
  };
  const [userData, setUserData] = useState(initUserData);
  const [errorField, setErrorField] = useState([]);
  //const [isBusy, setIsBusy] = useState(false);
  const validateFields = () => {
    var errorList = [];
    errorList = isEmpty(userData, "user_name", errorList);
    errorList = isEmpty(userData, "user_first_name", errorList);
    errorList = isEmpty(userData, "user_last_name", errorList);

    setErrorField([...errorList]);
    return errorList.length > 0 || errorField.length > 0 ? false : true;
  };

  const onChangeFormField = (e) => {
    let field_name = e.target.name;
    let field_value = e.target.value;
    if (errorField.includes(field_name)) {
      let errors = errorField.filter((x) => x != field_name);
      setErrorField([...errors]);
    }
    userData[field_name] = field_value;
    setUserData({ ...userData });
    console_log("userData", userData);

  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const [registrationDate, setRegistrationDate] = useState(new Date());
  const setUserRegistrationDate = (d) => {
    console.log(d);
    setRegistrationDate(d);
  };

  return (
    <div className={`company-user-box ${boxClass}`}>
      <h6 className="main-font-color text-center">{boxName} - {boxNum}</h6>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>First Name:</label>
            <input
              type="text"
              className={
                "form-control" +
                (errorField.includes("first_name") ? " is-invalid" : "")
              }
              id="first_name"
              name="first_name"
              placeholder="First Name"
              onChange={(e) => {
                onChangeFormField(e);
              }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Last Name:</label>
            <input
              type="text"
              className={
                "form-control" +
                (errorField.includes("last_name") ? " is-invalid" : "")
              }
              id="last_name"
              name="last_name"
              placeholder="Last Name"
              onChange={(e) => {
                onChangeFormField(e);
              }}
            />
          </div>
        </div>    
        <div className="col-md-12">
          <div className="form-group">
            <label>Date of Birth:</label>
            <DatePicker
              className={
                "form-control" +
                (errorField.includes("registration_date") ? " is-invalid" : "")
              }
              id="registration_date"
              name="registration_date"
              placeholder="MM/DD/YYYY"
              dateFormat="MM/dd/yyyy"
              maxDate={new Date()}
              minDate={new Date(1950, 1, 1)}
              yearDropdownItemNumber={100}
              scrollableYearDropdown={true}
              showYearDropdown
              selected={registrationDate}
              onChange={(d) => {
                setUserRegistrationDate(d);
              }}
            />
          </div>
        </div>    
        <div className="col-md-12">
          <div className="form-group">
            <label>Address:</label>
            <input
              type="text"
              className={
                "form-control" +
                (errorField.includes("address") ? " is-invalid" : "")
              }
              id="address"
              name="address"
              placeholder="Address"
              onChange={(e) => {
                onChangeFormField(e);
              }}
            />
          </div>
        </div>     
        <div className="col-md-6">
          <div className="form-group">
            <label>State:</label>
            <input
              type="text"
              className={
                "form-control" +
                (errorField.includes("state") ? " is-invalid" : "")
              }
              id="state"
              name="state"
              placeholder="State"
              onChange={(e) => {
                onChangeFormField(e);
              }}
            />
          </div>
        </div>     
        <div className="col-md-6">
          <div className="form-group">
            <label>City:</label>
            <input
              type="text"
              className={
                "form-control" +
                (errorField.includes("city") ? " is-invalid" : "")
              }
              id="city"
              name="city"
              placeholder="City"
              onChange={(e) => {
                onChangeFormField(e);
              }}
            />
          </div>
        </div>     
        <div className="col-md-6">
          <div className="form-group">
            <label>Zip Code:</label>
            <input
              type="text"
              className={
                "form-control" +
                (errorField.includes("zip_code") ? " is-invalid" : "")
              }
              id="zip_code"
              name="zip_code"
              placeholder="Zip Code"
              onChange={(e) => {
                onChangeFormField(e);
              }}
            />
          </div>
        </div>         
        <div className="col-md-6">
          <div className="form-group">
            <label>Country:</label>
            <select
              id="country"
              name="country"
              className={
                "form-control" +
                (errorField.includes("country") ? " is-invalid" : "")
              }
              onChange={(e) => {
                onChangeFormField(e);
              }}
              value={get_data_value(userData, "country", "US")}
            >
              {countryList.map((countryItem, index) => (
                <option key={index} value={countryItem.code}>
                  {countryItem.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label>Year Moved to Address:</label>
            <input
              type="text"
              className={
                "form-control" +
                (errorField.includes("address1") ? " is-invalid" : "")
              }
              id="address1"
              name="address1"
              placeholder="Address"
              onChange={(e) => {
                onChangeFormField(e);
              }}
            />
          </div>
        </div> 
      </div>
    </div>
  );
};

export default withRouter(CompanyUserBlock);
